import {useAuth, useTitle} from '../hooks';
import {AlertContext, api} from '../services';
import {Fragment, useContext, useEffect, useState} from 'react';
import {Alert, BtnShowPassword, FindCompany, FindFolder} from '../components';
import {LoadingButton} from '@mui/lab';
import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import AddIcon from '@mui/icons-material/Add';
import {filterObject} from '../utils/filterObject';
import {useParams} from 'react-router-dom';
import EditIcon from '@mui/icons-material/EditOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import {phoneMask} from '../utils';

function CreateOrEditUser() {
    const {setTitle} = useTitle();
    const {handleLogout, setLoadingBackdrop} = useAuth();
    const {user_id: userId} = useParams();
    const {newAlert} = useContext(AlertContext);
    const [errors, setErrors] = useState({});
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [emailNotifications, setEmailNotifications] = useState(true);
    const [phone, setPhone] = useState('');
    const [phoneNotifications, setPhoneNotifications] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([{id: 0, name: 'Carregando ...'}]);
    const [companies, setCompanies] = useState([]);
    const [folders, setFolders] = useState([]);
    const [role, setRole] = useState([]);
    const [count, setCount] = useState(1);

    function handleChange(event, index = null) {
        if (event.target.name === 'name') setName(event.target.value);
        else if (event.target.name === 'email') setEmail(event.target.value);
        else if (event.target.name === 'phone') setPhone(event.target.value);
        else if (event.target.name === 'phone_notifications') setPhoneNotifications(event.target.checked);
        else if (event.target.name === 'email_notifications') setEmailNotifications(event.target.checked);
        else if (event.target.name === 'role') {
            setRole(role => {
                const newRole = {...role};

                newRole[index] = event.target.value;

                return newRole;
            });
        }

        const name = event.target.name === 'phone_notifications' ? 'phone' : event.target.name;

        if (errors[name]) {
            setErrors(filterObject(errors, index => index !== name));
        }
    }

    function handlePhone(event) {
        const input = event.target;

        input.value = phoneMask(input.value);
    }

    function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);

        const password = event?.target?.password?.value;
        const password_confirmation = event?.target?.password_confirmation?.value;
        const phoneNumeric = phone.replace(/[^0-9]/g, '');

        if (name.length < 3) {
            setErrors({
                name: 'O nome precisa ter pelo menos 3 caracteres.',
            });
            setLoading(false);
            return;
        }

        if (!email) {
            setErrors({
                email: 'O e-mail é obrigatório.',
            });
            setLoading(false);
            return;
        }

        if ((!userId || password.length < 0) && password.length < 8) {
            setErrors({
                password: 'A senha precisa ter pelo menos 8 caracteres.',
            });
            setLoading(false);
            return;
        }

        if (password !== password_confirmation) {
            setErrors({
                password_confirmation: 'A senha e a confirmação precisam ser iguais.',
            });
            setLoading(false);
            return;
        }

        if (phone.length > 0 && phone.length < 11) {
            setErrors({
                name: 'O telefone precisa ter 11 dígitos.',
            });
            setLoading(false);
            return;
        }

        if (phoneNotifications && !phoneNumeric) {
            setErrors({
                phone: 'O telefone é obrigatório se desejar receber notificações.',
            });
            setLoading(false);
            return;
        }

        let apiMethod = api.post;
        let apiEndpoint = '/users';

        if (userId) {
            apiMethod = api.put;
            apiEndpoint += '/' + userId;
        }

        apiMethod(apiEndpoint, {
            name,
            email,
            email_notifications: emailNotifications,
            phone: phoneNumeric,
            phone_notifications: phoneNotifications,
            password,
            password_confirmation,
            is_admin: isAdmin,
            folders,
            role,
        })
            .then(response => {
                newAlert(response.data?.message, 'success');

                event.target.reset();

                if (!userId) {
                    setName('');
                    setEmail('');
                    setPhone('');
                    setPhoneNotifications(true);
                    setCompanies([]);
                    setFolders([]);
                    setRole([]);
                }
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    const action = userId ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao ${action} o usuário. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    function handleSetFolder(i, value) {
        setFolders(folders => {
            const newFolders = {...folders};

            newFolders[i] = value;

            return newFolders
        })
    }

    function handleSetCompany(i, value) {
        setCompanies(companies => {
            const newCompanies = {...companies};

            newCompanies[i] = value;

            return newCompanies
        })
    }

    // eslint-disable-next-line
    useEffect(() => setTitle(!userId ? 'Novo usuário' : 'Editar usuário'), []);

    useEffect(() => {
        api.get('/roles/all')
            .then(response => setRoles(response.data?.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao carregar as funções. Tente novamente mais tarde.');
            })
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (!userId) return;

        setLoadingBackdrop(true);

        api.get('/users/' + userId)
            .then(response => {
                response = response.data?.data;

                setName(response?.name ?? '');
                setEmail(response?.email ?? '');
                setEmailNotifications(response?.email_notifications ?? true);
                setPhone(response?.phone ?? '');
                setPhoneNotifications(response?.phone_notifications ?? true);
                setIsAdmin(Boolean(response?.is_admin));

                if (!Boolean(response?.is_admin)) {
                    setCount(response?.folders?.length ?? 1);
                    setCompanies(response?.companies ?? []);
                    setFolders(response?.folders ?? []);
                    setRole(response?.roles ?? []);
                }
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar o usuário. Tente novamente mais tarde.');
                }
            })
            .finally(() => setLoadingBackdrop(false))
        // eslint-disable-next-line
    }, [userId]);

    return (
        <Grid
            container
            component="form"
            className="self-start"
            onSubmit={handleSubmit}
            spacing={2}
            noValidate={true}
        >
            <Grid size={12}>
                <Typography variant="h5" className="mt-3">
                    Detalhes da Conta
                </Typography>
                <Divider className="mt-2"/>
            </Grid>
            <Grid size={{xs: 12, md: 6}}>
                <TextField
                    name="name"
                    label="Nome"
                    variant="outlined"
                    margin="normal"
                    type="text"
                    value={name}
                    onChange={handleChange}
                    required
                    fullWidth
                    autoFocus
                    error={Boolean(errors?.name)}
                    helperText={errors?.name}
                    autoComplete="name"
                />
            </Grid>
            <Grid size={{xs: 12, md: 6}}>
                <TextField
                    name="email"
                    label="E-mail"
                    variant="outlined"
                    margin="normal"
                    type="email"
                    value={email}
                    onChange={handleChange}
                    required
                    fullWidth
                    error={Boolean(errors?.email)}
                    helperText={errors?.email}
                    autoComplete="username"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            name="email_notifications"
                            checked={emailNotifications}
                            onChange={handleChange}
                        />
                    }
                    label="Deseja receber notificações por e-mail."
                />
            </Grid>
            <Grid size={{xs: 12, md: 6}}>
                <TextField
                    name="password"
                    label="Senha"
                    variant="outlined"
                    margin="normal"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    required={Boolean(!userId)}
                    onChange={handleChange}
                    error={Boolean(errors?.password)}
                    helperText={errors?.password}
                    autoComplete="new-password"
                    slotProps={{
                        input: {
                            endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                        },
                    }}
                />
            </Grid>
            <Grid size={{xs: 12, md: 6}}>
                <TextField
                    name="password_confirmation"
                    label="Confirme a senha"
                    variant="outlined"
                    margin="normal"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    required={Boolean(!userId)}
                    onChange={handleChange}
                    error={Boolean(errors?.password_confirmation)}
                    helperText={errors?.password_confirmation}
                    autoComplete="new-password"
                    slotProps={{
                        input: {
                            endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                        },
                    }}
                />
            </Grid>
            <Grid size={{xs: 12, md: 6}}>
                <TextField
                    name="phone"
                    label="Telefone"
                    variant="outlined"
                    margin="normal"
                    type="tel"
                    value={phoneMask(phone)}
                    onChange={handleChange}
                    fullWidth
                    required={phoneNotifications}
                    error={Boolean(errors?.phone)}
                    helperText={errors?.phone}
                    onKeyUp={handlePhone}
                    slotProps={{
                        htmlInput: {
                            maxLength: 15,
                        },
                    }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            name="phone_notifications"
                            checked={phoneNotifications}
                            onChange={handleChange}
                        />
                    }
                    label="Deseja receber notificações por WhatsApp ou SMS."
                />
            </Grid>
            <Grid size={{xs: 12, md: 6}} className="flex justify-center">
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={isAdmin}
                            onChange={event => setIsAdmin(event.target.checked)}
                        />
                    }
                    label="É administrador"
                />
            </Grid>
            {
                !isAdmin && (
                    <>
                        <Grid size={12}>
                            <Typography variant="h5">
                                Permissões
                            </Typography>
                            <Divider className="mt-2"/>
                        </Grid>
                        {
                            [...Array(count)].map((x, i) => (
                                <Fragment key={i}>
                                    <Grid size={12}>
                                        <Grid
                                            container
                                            className="mb-2"
                                            spacing={2}
                                        >
                                            <Grid size={12}>
                                                <FindCompany
                                                    company={companies[i] ?? null}
                                                    setCompany={value => handleSetCompany(i, value)}
                                                    setFolder={value => handleSetFolder(i, value)}
                                                />
                                            </Grid>
                                            <Grid size={{xs: 12, md: 6}}>
                                                <FindFolder
                                                    folder={folders[i] ?? null}
                                                    company={companies[i] ?? null}
                                                    setFolder={value => handleSetFolder(i, value)}
                                                />
                                            </Grid>
                                            <Grid size={{xs: 12, md: 6}}>
                                                <Alert
                                                    severity="error"
                                                    className="flex justify-center mb-2"
                                                    mmessage={errors[`role.${i}`] ?? ''}
                                                />
                                                <FormControl fullWidth>
                                                    <InputLabel id={`select-associate-folder-label-${i}`}>Função</InputLabel>
                                                    <Select
                                                        labelId={`select-associate-folder-label-${i}`}
                                                        id={`select-associate-folder-${i}`}
                                                        name="role"
                                                        value={role[i] ?? ''}
                                                        label="Função"
                                                        onChange={event => handleChange(event, i)}
                                                    >
                                                        {
                                                            roles.map(role => (
                                                                <MenuItem
                                                                    key={role.id}
                                                                    value={role.id}
                                                                >
                                                                    {role.name}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                        {
                                            i + 1 !== count &&
                                                <Grid size={12}>
                                                    <Divider className="mt-6 mb-3"/>
                                                </Grid>
                                        }
                                    </Grid>
                                </Fragment>
                            ))
                        }
                        <Grid size={12}>
                            <Grid
                                container
                                justifyContent="center"
                                className="mb-2"
                            >
                                <IconButton
                                    color="error"
                                    aria-label="Remover a última pasta"
                                    onClick={() => setCount(count - 1)}
                                    disabled={count === 1}
                                >
                                    <RemoveIcon/>
                                </IconButton>
                                <IconButton
                                    color="success"
                                    aria-label="Adicionar mais um pasta"
                                    onClick={() => setCount(count + 1)}
                                >
                                    <AddIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </>
                )
            }
            <Grid className="mx-auto w-9/12">
                <LoadingButton
                    startIcon={userId ? <EditIcon/> : <AddIcon/>}
                    disabled={Object.keys(errors).length > 0}
                    fullWidth
                    type="submit"
                    loading={loading}
                >
                    {userId ? 'Editar' : 'Criar'} usuário
                </LoadingButton>
            </Grid>
        </Grid>
    );
}

export default CreateOrEditUser;

export {CreateOrEditUser};