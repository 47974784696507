import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {useContext, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {AlertContext, api, TreeContext} from '../services';
import useAuth from '../hooks/useAuth';
import {uppercaseFirst} from '../utils';
import BtnClose from "./BtnClose";

function ConfirmDisassociateMonitor({callback = () => null}) {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const {showConfirmDisassociate, setShowConfirmDisassociate} = useContext(TreeContext);
    const type = showConfirmDisassociate.type === 'lubricator' ? 'lubrificador' : 'respiro';

    function disassociate() {
        setLoading(true);

        api
            .patch(`/${showConfirmDisassociate.type}s/${showConfirmDisassociate.id}/disassociate`)
            .then(response => {
                newAlert(response?.data?.message, 'success');
                callback();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? `Erro ao tentar desassociar o ${uppercaseFirst(type)} #${showConfirmDisassociate.id}. Tente novamente mais tarde.`);
                }
            })
            .finally(() => {
                setLoading(false);
                setShowConfirmDisassociate(false);
            });
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={Boolean(showConfirmDisassociate)}
            onClose={() => setShowConfirmDisassociate(false)}
            fullScreen={fullScreen}
        >
            <DialogTitle>
                <Box>
                    Desassociar o {uppercaseFirst(type)} <span className="font-bold">#{showConfirmDisassociate.id}</span>?
                </Box>
                <BtnClose onClick={() => setShowConfirmDisassociate(false)} />
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Você tem certeza que deseja desassociar esse {type}?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    variant="outlined"
                    onClick={() => setShowConfirmDisassociate(false)}
                >
                    Cancelar
                </Button>
                <LoadingButton
                    variant="outlined"
                    color="error"
                    loading={loading}
                    onClick={disassociate}
                >
                    Desassociar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDisassociateMonitor;

export {ConfirmDisassociateMonitor};