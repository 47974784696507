import {api} from '../services';
import {useEffect, useState} from 'react';
import {Autocomplete, TextField, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {uppercaseFirst} from '../utils';
import AirIcon from '@mui/icons-material/Air';
import WaterDropIcon from '@mui/icons-material/WaterDropOutlined';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';

function FindNewMonitor({type, monitor, setMonitor, handleChange}) {
    const [query, setQuery] = useState('');
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [timeoutSearch, setTimeoutSearch] = useState(null);
    const types = {
        lubricator: {
            text: 'lubrificador',
            icon: WaterDropIcon,
        },
        breather: {
            text: 'respiro',
            icon: AirIcon,
        },
        'level-display': {
            text: 'visor de nível',
            icon: DeviceThermostatIcon,
        },
    };
    const text = types[type]?.text;
    const Icon = types[type]?.icon;

    const handleDisableSubmit = event => event.keyCode === 13 && event.preventDefault() && setQuery(search);

    const handleTimeoutSearch = () => {
        clearTimeout(timeoutSearch);
        setTimeoutSearch(setTimeout(() => setQuery(search), 1000));
    };

    useEffect(() => {
        if (!query) {
            let newOptions = [];

            if (monitor) {
                newOptions = [monitor];
            }

            setOptions(newOptions);
            return undefined;
        }

        setLoading(true);

        api
            .get(`/${type}s/associate`, {
                params: {
                    query,
                }
            })
            .then(response => {
                let newOptions = [];

                if (response.data) {
                    newOptions = [...response.data.data];
                }

                setOptions(newOptions);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, [monitor, query]);

    if (!text) return false;

    return (
        <Autocomplete
            autoComplete
            includeInputInList
            value={monitor}
            options={options}
            loading={loading}
            loadingText="Buscando ..."
            noOptionsText={query ? `Nenhum ${text} encontrado` : `Digite o serial ou id do ${text}`}
            filterOptions={x => x}
            getOptionLabel={option => `${uppercaseFirst(text)} #${option.id}`}
            onInputChange={(event, value) => setSearch(value)}
            onChange={(event, value) => {
                setQuery('');
                setMonitor(value);
                handleChange();
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label={uppercaseFirst(text)}
                    fullWidth
                    onKeyUp={handleTimeoutSearch}
                    onKeyDown={handleDisableSubmit}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={props.key}>
                    <Grid container alignItems="center">
                        <Grid sx={{display: 'flex', width: 44}}>
                            <Icon/>
                        </Grid>
                        <Grid sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                            <Grid container direction="column">
                                <Grid>
                                    {uppercaseFirst(text)} #{option.id}
                                </Grid>
                                <Grid>
                                    <Typography variant="caption">
                                        {option.serial}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </li>
            )}
        />
    );
}

export default FindNewMonitor;

export {FindNewMonitor};