import {CardActions, CardContent, Checkbox, FormControlLabel, Link, TextField} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import {api} from '../services';
import {useState} from 'react';
import ForgotPassword from './ForgotPassword';
import BtnShowPassword from './BtnShowPassword';
import {filterObject} from '../utils/filterObject';

function PasswordChange({newAlert, handleLogout, email}) {
    const [disableChangePasswordBtn, setDisableChangePasswordBtn] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [forgotPassword, setForgotPassword] = useState(false);
    const handleForgotPasswordClick = () => setForgotPassword(true);

    function handleChange(event) {
        if (errors[event.target.name]) {
            setErrors(filterObject(errors, index => index !== event.target.name));
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        setDisableChangePasswordBtn(true);

        const current_password = event?.target?.current_password?.value;
        const new_password = event?.target?.new_password?.value;
        const new_password_confirmation = event?.target?.new_password_confirmation?.value;
        const disconnect = event?.target?.disconnect?.checked;

        if (new_password !== new_password_confirmation) {
            setErrors({
                new_password: 'As senhas não conferem.',
                new_password_confirmation: 'As senhas não conferem.',
            });
            setDisableChangePasswordBtn(false);
            return;
        }

        if (current_password.length < 8) {
            setErrors({
                current_password: 'A senha precisa ter pelo menos 8 caracteres.',
            });
            setDisableChangePasswordBtn(false);
            return;
        }

        if (new_password.length < 8) {
            setErrors({
                new_password: 'A nova senha precisa ter pelo menos 8 caracteres.',
                new_password_confirmation: 'A nova senha precisa ter pelo menos 8 caracteres.',
            });
            setDisableChangePasswordBtn(false);
            return;
        }

        if (new_password === current_password) {
            setErrors({
                new_password: 'A nova senha não pode ser igual a senha atual.',
                new_password_confirmation: 'A nova senha não pode ser igual a senha atual.',
            });
            setDisableChangePasswordBtn(false);
            return;
        }

        api
            .patch('/change-password', {
                current_password,
                new_password,
                new_password_confirmation,
                disconnect,
            })
            .then(response => {
                newAlert(response?.data?.message, 'success')
                event.target.reset();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar trocar senha. Tente novamente mais tarde.');
                }
            })
            .finally(() => setDisableChangePasswordBtn(false));
    }

    return (
        <>
            <Grid
                component="form"
                className="p-2 mx-auto mb-auto"
                onSubmit={handleSubmit}
            >
                <CardContent>
                    <TextField
                        name="current_password"
                        label="Senha atual"
                        variant="outlined"
                        margin="normal"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        required
                        onChange={handleChange}
                        error={Boolean(errors?.current_password)}
                        helperText={errors?.current_password}
                        autoComplete="current-password"
                        slotProps={{
                            input: {
                                endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                            },
                        }}
                    />
                    <Grid textAlign="right">
                        <Link
                            underline="none"
                            onClick={handleForgotPasswordClick}
                            component="button"
                            variant="body2"
                            type="button"
                        >
                            Esqueceu a senha?
                        </Link>
                    </Grid>
                    <TextField
                        name="new_password"
                        label="Nova senha"
                        variant="outlined"
                        margin="normal"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        required
                        onChange={handleChange}
                        error={Boolean(errors?.new_password)}
                        helperText={errors?.new_password}
                        autoComplete="new-password"
                        slotProps={{
                            input: {
                                endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                            },
                        }}
                    />
                    <TextField
                        name="new_password_confirmation"
                        label="Confirme a nova senha"
                        variant="outlined"
                        margin="normal"
                        type={showPassword ? 'text' : 'password'}
                        fullWidth
                        required
                        onChange={handleChange}
                        error={Boolean(errors?.new_password_confirmation)}
                        helperText={errors?.new_password_confirmation}
                        autoComplete="new-password"
                        slotProps={{
                            input: {
                                endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                            },
                        }}
                    />
                    <FormControlLabel
                        control={<Checkbox defaultChecked={true} name="disconnect"/>}
                        label="Desconetar outros dispositivos"
                    />
                </CardContent>
                <CardActions className="p-3">
                    <LoadingButton
                        type="submit"
                        loading={disableChangePasswordBtn}
                        fullWidth
                        disabled={Object.keys(errors).length > 0}
                    >
                        Atualizar senha
                    </LoadingButton>
                </CardActions>
            </Grid>
            <ForgotPassword
                forgotPassword={forgotPassword}
                setForgotPassword={setForgotPassword}
                email={email}
                handleChange={() => null}
                errors={errors}
                setErrors={setErrors}
                disabled={true}
            />
        </>
    );
}

export default PasswordChange;

export {PasswordChange};