import Alert from './Alert'
import uppercaseFirst from '../utils/uppercaseFirst';

const ActiveAlerts = ({activeAlerts, type}) => {
    const text = type === 'lubricator' ? 'lubrificador' : 'respiro'

    if (!activeAlerts?.length) {
        return (
            <Alert
                severity="success"
                title="Tudo em ordem!"
                message={uppercaseFirst(text) + ' sem alertas ativos até o momento.'}
            />
        );
    }

    return activeAlerts.map(alert => {
        let severity;
        let title;
        let message;

        switch (alert.type) {
            case 'error':
                severity = 'error';
                title = 'Falha identificada!';
                message = 'O lubrificador comunicou uma falha em ' + alert.started_at + '.';
                break;
            case 'offline':
                severity = 'error';
                title = 'Lubrificador não responde!';
                message = 'Estamos sem comunicação com esse lubrificador desde ' + alert.started_at + '.';
                break;
            case 'grease':
                severity = 'warning';
                title = 'Lubrificante chegando ao fim!';
                message = 'O lubrificante deste lubrificador está chegando ao fim.';
                break;
            case 'battery':
                severity = 'warning';
                title = 'Bateria fraca!';
                message = 'O ' + text + ' está com menos de 10% de bateria desde ' + alert.started_at + '.';
                break;
            case 'saturated':
                severity = 'error';
                title = 'Respiro Saturado!';
                message = 'O respiro saturou desde ' + alert.started_at + ' e precisa ser substituído.';
                break;
            default:
                severity = 'error';
        }

        return (
            <Alert
                severity={severity}
                title={title}
                message={message}
                key={alert.id}
                className="mb-3"
            />
        );
    });
}

export default ActiveAlerts;

export {ActiveAlerts};