import cnpjMask from '../utils/cnpjMask';
import {useAuth, useTitle} from '../hooks';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useState} from 'react';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {
    BtnClose,
    CreateOrEditFolder,
    InputSearch,
    StyledTableCell,
    StyledTableRow,
    TablePagination,
    TableSortLabel
} from '../components';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined';

function ListCompanies() {
    const theme = useTheme();
    const {setTitle} = useTitle();
    const {workspace, handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [query, setQuery] = useState('');
    const [order, setOrder] = useState('asc');
    const [perPage, setPerPage] = useState(10);
    const [orderBy, setOrderBy] = useState('folders.id');
    const [company, setCompany] = useState(null);
    const [companies, setCompanies] = useState({});
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);

    const confirmDelete = (id, name) => setShowConfirmDelete({id, name});

    function deleteCompany() {
        api
            .delete(`/folders/${showConfirmDelete?.id}`)
            .then(response => {
                newAlert(response?.data?.message, 'success');
                setCompanies(company => {
                    const newCompanies = {...company};

                    newCompanies.data = newCompanies.data.filter(company => company.id !== showConfirmDelete?.id);
                    newCompanies.meta.total--;

                    return newCompanies;
                });
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao tentar excluir a empresa. Tente novamente mais tarde.');
            })
            .finally(() => setShowConfirmDelete(false));
    }

    function loadCompanies() {
        setLoading(true);

        api
            .get('/folders', {
                params: {
                    page: page + 1,
                    per_page: perPage,
                    order,
                    order_by: orderBy,
                    query,
                },
            })
            .then(response => setCompanies(response.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    setCompanies({});
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar empresas. Tente novamente mais tarde.');
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => setTitle('Empresas'), [setTitle]);

    // eslint-disable-next-line
    useEffect(loadCompanies, [page, perPage, workspace, order, orderBy, query]);

    return (
        <>
            <Box className="self-start text-center w-full">
                <Button className="w-9/12" onClick={() => setCompany({action: 'create'})}>
                    <AddIcon/>
                    &nbsp;Nova Empresa
                </Button>
            </Box>
            <Box className="self-start w-full">
                <InputSearch
                    setQuery={setQuery}
                    setPage={setPage}
                    placeholder="Pesquisar por id, nome, cnpj, unidade, cidade ou estado ..."
                />
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table" sx={{minWidth: 250}} size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    [
                                        {label: 'folders.id', text: 'ID', short: true},
                                        {label: 'folders.name', text: 'Nome', short: true},
                                        {label: 'folders.cnpj', text: 'CNPJ', short: true},
                                        {label: 'folders.unit', text: 'Unidade', short: true},
                                        {label:'addresses.city', text: 'Cidade', short: true},
                                        {label: 'addresses.state', text: 'Estado', short: true},
                                        {label: 'actions', text: 'Ações', short: false},
                                    ].map(({label, text, short}) => (
                                        <TableSortLabel
                                            key={label}
                                            label={label}
                                            text={text}
                                            short={short}
                                            order={order}
                                            setOrder={setOrder}
                                            orderBy={orderBy}
                                            setOrderBy={setOrderBy}
                                        />
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading ? (
                                        <TableRow>
                                            <TableCell colSpan={7} align="center">
                                                <CircularProgress className="m-auto my-3"/>
                                            </TableCell>
                                        </TableRow>
                                    ) :
                                    (
                                        (companies && companies?.data?.map(company => (
                                                <StyledTableRow key={company?.id}>
                                                    <StyledTableCell align="center">
                                                        {company?.id}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {company?.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {company?.cnpj ? cnpjMask(company?.cnpj) : '-'}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {company?.unit ?? '-'}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {company?.city ?? '-'}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {company?.state ?? '-'}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <Tooltip title="Detalhar">
                                                            <IconButton
                                                                aria-label="Detalhar"
                                                                color="primary"
                                                                onClick={() => {
                                                                    setCompany({id: company?.id, action: 'show'})
                                                                }}
                                                            >
                                                                <VisibilityIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Editar">
                                                            <IconButton
                                                                aria-label="Editar"
                                                                color="warning"
                                                                onClick={() => {
                                                                    setCompany({id: company?.id, action: 'edit'})
                                                                }}
                                                            >
                                                                <EditIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Apagar">
                                                            <IconButton
                                                                aria-label="Apagar"
                                                                color="error"
                                                                onClick={() => confirmDelete(company?.id, company?.name)}
                                                            >
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                        )))
                                    )
                            }
                            {
                                !loading && !companies?.data?.length && (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            <Alert severity="warning">
                                                Nenhuma empresa encontrado para os dados informados.
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={7}
                                    rowsPerPage={perPage}
                                    setPerPage={setPerPage}
                                    page={page}
                                    setPage={setPage}
                                    count={companies?.meta?.total ?? 0}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={Boolean(showConfirmDelete?.id)}
                onClose={() => setShowConfirmDelete(false)}
                fullScreen={fullScreen}
            >
                <DialogTitle>
                    <Box>
                        Excluir a empresa <span className="font-bold">{showConfirmDelete?.name}</span>?
                    </Box>
                    <BtnClose onClick={() => setShowConfirmDelete(false)}/>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        A exclusão dessa empresa removerá todos os dados associados a ela.
                    </DialogContentText>
                    <div className="text-center font-bold mt-3">
                        ESTA AÇÃO NÃO PODERÁ SER DESFEITA!
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        variant="outlined"
                        onClick={() => setShowConfirmDelete(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={deleteCompany}
                    >
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>
            <CreateOrEditFolder
                folder={company}
                setFolder={setCompany}
                fullScreen={fullScreen}
                loadFolders={loadCompanies}
                type="empresa"
            />
        </>
    );
}

export default ListCompanies;

export {ListCompanies};