import {useAuth} from '../hooks';
import {AlertContext, api} from '../services';
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, InputAdornment, TextField,} from '@mui/material';
import BtnClose from './BtnClose';
import {useContext, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {filterObject, uppercaseFirst} from '../utils';
import EditIcon from '@mui/icons-material/EditOutlined';
import AddIcon from '@mui/icons-material/Add';

function CreateOrEditMonitor({type, monitor, onClose, fullScreen, loadMonitors}) {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const types = {
        lubricator: 'lubrificador',
        breather: 'respiro',
        'level-display': 'visor de nível',
    };
    const text = types[type];

    function handleChange(event) {
        if (errors[event.target.id]) {
            setErrors(filterObject(errors, index => index !== event.target.id));
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        const serial = event.target?.serial?.value;
        const capacity = parseInt(event.target?.capacity?.value);

        let data;

        if (!serial) {
            setErrors({
                serial: `O número de série do ${text} é obrigatório.`,
            });
            setLoading(false);
            return;
        }

        if (type === 'lubricator') {
            if (!Number.isInteger(capacity) || capacity < 1) {
                setErrors({
                    capacity: 'A capacidade precisa ser um número inteiro maior que 0.',
                });
                setLoading(false);
                return;
            }

            data = {serial, capacity}
        } else {
            data = {serial};
        }

        let apiMethod = api.post;
        let apiEndpoint = `/${type}s`;

        if (monitor?.id) {
            apiMethod = api.put;
            apiEndpoint += '/' + monitor.id;
        }

        setLoading(true);

        apiMethod(apiEndpoint, data)
            .then(response => {
                onClose();
                newAlert(response?.data?.message, 'success');
                loadMonitors();
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    const action = monitor?.id ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} o ${text}. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    return (
        <Dialog
            fullWidth
            maxWidth="xs"
            open={Boolean(monitor)}
            onClose={onClose}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>
                <Box>
                    {monitor?.id ? `Editar ${uppercaseFirst(text)} #${monitor?.id}` : `Novo ${text}`}
                </Box>
                <BtnClose onClick={onClose} />
            </DialogTitle>
            <DialogContent dividers>
                <TextField
                    id="serial"
                    label="Nº de série"
                    variant="outlined"
                    margin="normal"
                    type="text"
                    defaultValue={monitor?.serial ?? ''}
                    onChange={handleChange}
                    fullWidth
                    autoFocus
                    error={Boolean(errors?.serial)}
                    helperText={errors?.serial}
                />
                {
                    type === 'lubricator' && (
                        <TextField
                            id="capacity"
                            label="Capacidade"
                            variant="outlined"
                            margin="normal"
                            type="number"
                            defaultValue={monitor?.capacity ?? ''}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors?.capacity)}
                            helperText={errors?.capacity}
                            slotProps={{
                                input: {
                                    endAdornment: <InputAdornment position="end">g</InputAdornment>,
                                },
                            }}
                        />
                    )
                }
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    startIcon={monitor?.id ? <EditIcon/> : <AddIcon/>}
                    disabled={Object.keys(errors).length > 0}
                    fullWidth
                    type="submit"
                    loading={loading}
                >
                    {monitor?.id ? 'Editar' : 'Criar'} {text}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default CreateOrEditMonitor;

export {CreateOrEditMonitor};