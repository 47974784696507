import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import {AlertContext, api} from '../services';
import {useAuth} from '../hooks';
import {useContext, useState} from 'react';
import {LoadingButton} from '@mui/lab';
import {uppercaseFirst} from '../utils';
import BtnClose from "./BtnClose";

function ConfirmDeleteMonitor({type, fullScreen, showConfirmDelete, onClose, setMonitors}) {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const types = {
        lubricator: 'lubrificador',
        breather: 'respiro',
        'level-display': 'visor de nível',
    };
    const text = types[type];

    function deleteMonitor() {
        setLoading(true);

        api
            .delete(`/${type}s/${showConfirmDelete}`)
            .then(response => {
                newAlert(response?.data?.message, 'success');
                setMonitors(monitors => {
                    const newMonitors = {...monitors};

                    newMonitors.data = newMonitors.data.filter(monitor => monitor.id !== showConfirmDelete);
                    newMonitors.meta.total--;

                    return newMonitors;
                });
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? `Erro ao tentar excluir o ${uppercaseFirst(text)} #${showConfirmDelete}. Tente novamente mais tarde.`);
            })
            .finally(() => {
                setLoading(false);
                onClose();
            });
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={Boolean(showConfirmDelete)}
            onClose={onClose}
            fullScreen={fullScreen}
        >
            <DialogTitle>
                <Box>
                    Excluir o {uppercaseFirst(text)} <span className="font-bold">#{showConfirmDelete}</span>?
                </Box>
                <BtnClose onClick={onClose} />
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    A exclusão desse {text} removerá todos os status associados a ele.
                </DialogContentText>
                <div className="text-center font-bold mt-3">
                    ESTA AÇÃO NÃO PODERÁ SER DESFEITA!
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    variant="outlined"
                    onClick={onClose}
                >
                    Cancelar
                </Button>
                <LoadingButton
                    variant="outlined"
                    color="error"
                    loading={loading}
                    onClick={deleteMonitor}
                >
                    Deletar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDeleteMonitor;

export {ConfirmDeleteMonitor};