import {useContext, useEffect, useState} from 'react';
import {useAuth} from '../hooks';
import {AlertContext, api} from '../services';
import {RefreshedAt, StyledTableCell, StyledTableRow, TablePagination} from './';
import {
    Alert,
    Box,
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';

function LatestStatus({lubricator, breather, dictionary}) {
    const {newAlert} = useContext(AlertContext)
    const {handleLogout} = useAuth();
    const [loading, setLoading] = useState(true);
    const [statuses, setStatuses] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const monitor = lubricator ? 'lubrificador' : 'respiro';

    function loadStatuses() {
        setLoading(true);

        api
            .get('/statuses', {
                params: {
                    lubricator: lubricator,
                    breather: breather,
                    page: page + 1,
                    per_page: perPage,
                }
            })
            .then(response => setStatuses(response?.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 404) newAlert(`O ${monitor} solicitado não foi encontrado.`);
                else if (code === 403) newAlert(`Você não tem permissão para acessar os status desse ${monitor}.`);
                else newAlert(`Erro ao tentar carregar os status desse ${monitor}. Tente novamente mais tarde.`);

                setStatuses([]);
            })
            .finally(() => setLoading(false));
    }

    // eslint-disable-next-line
    useEffect(loadStatuses, [lubricator, breather, page, perPage]);

    if (loading) return <CircularProgress/>;

    return (
        <>
            <RefreshedAt
                className="self-start my-1"
                hour={statuses.hour}
                refresh={loadStatuses}
            />
            <TableContainer component={Paper}>
                <Table stickyHeader aria-label="sticky table" sx={{minWidth: 250}} size="small">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="center">Status</StyledTableCell>
                            <StyledTableCell align="center">Bateria</StyledTableCell>
                            <StyledTableCell align="center">Data e Hora</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (statuses && statuses?.data?.map(status => {
                                const data = dictionary[status?.ok];

                                return (
                                    <StyledTableRow key={status?.id}>
                                        <StyledTableCell align="center">
                                            <Box className="flex items-center justify-center">
                                                <CircleIcon className="mr-1" sx={{color: data?.color}}/>
                                                {data?.text}
                                            </Box>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {status?.battery ? `${status?.battery}%` : '-'}
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {status?.datetime}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                )
                            }))
                        }
                        {
                            !statuses?.data?.length && (
                                <TableRow>
                                    <TableCell colSpan={3} align="center">
                                        <Alert severity="warning">
                                            Nenhum status encontrado para o {monitor} informado.
                                        </Alert>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={3}
                                rowsPerPage={perPage}
                                setPerPage={setPerPage}
                                page={page}
                                setPage={setPage}
                                count={statuses?.meta?.total}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
        </>
    );
}

export default LatestStatus;

export {LatestStatus};