import {useContext, useEffect, useState} from 'react';
import {AlertContext, api} from '../services';
import {
    Alert,
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {
    BtnClose,
    CreateOrEditRole,
    InputSearch,
    ListUserFolder,
    StyledTableCell,
    StyledTableRow,
    TablePagination
} from '../components';
import {useAuth, useTitle} from '../hooks';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

function Roles() {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const {setTitle} = useTitle();
    const [loading, setLoading] = useState(true);
    const [roles, setRoles] = useState({});
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const {newAlert} = useContext(AlertContext);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [role, setRole] = useState(false);
    const [query, setQuery] = useState('');
    const [showUsers, setShowUsers] = useState(false);

    useEffect(() => setTitle('Permissões'), [setTitle]);

    function loadRoles() {
        setLoading(true);

        api
            .get('/roles', {
                params: {
                    page: page + 1,
                    per_page: perPage,
                    query,
                }
            })
            .then(response => setRoles(response.data))
            .catch(() => {
                setRoles({});
                newAlert('Erro ao tentar carregar funções. Tente novamente mais tarde.');
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        loadRoles();
        // eslint-disable-next-line
    }, [page, perPage, query]);

    function confirmDelete(id, name) {
        if (id === 1) {
            newAlert('Não é possível excluir a função principal.');
            return;
        }

        setShowConfirmDelete({id, name});
    }

    function deleteRole() {
        api
            .delete(`/roles/${showConfirmDelete?.id}`)
            .then(response => {
                newAlert(response?.data?.message, 'success');
                setRoles(roles => {
                    const newRoles = {...roles};

                    newRoles.data = newRoles.data.filter(role => role.id !== showConfirmDelete?.id);
                    newRoles.meta.total--;

                    return newRoles;
                });
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao tentar excluir a função. Tente novamente mais tarde.');
            })
            .finally(() => setShowConfirmDelete(false));
    }

    return (
        <>
            <Box className="self-start text-center w-full">
                <Button className="w-9/12" onClick={() => setRole(true)}>
                    <AddIcon/>
                    &nbsp;Nova Função
                </Button>
            </Box>
            <Box className="self-start w-full">
                <InputSearch
                    setQuery={setQuery}
                    setPage={setPage}
                    placeholder="Pesquisar por nome ou descrição ..."
                />
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table" sx={{minWidth: 250}} size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    [
                                        'Função',
                                        'Descrição',
                                        'Permissões',
                                        'Usuários',
                                        'Ações',
                                    ].map((text, i) => (
                                        <StyledTableCell
                                            key={i}
                                            align="center"
                                        >
                                            {text}
                                        </StyledTableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading ? (
                                        <TableRow>
                                            <TableCell colSpan={5} align="center">
                                                <CircularProgress className="m-auto my-3"/>
                                            </TableCell>
                                        </TableRow>
                                    ) :
                                    (
                                        (roles && roles?.data?.map(role => (
                                            <StyledTableRow key={role?.id}>
                                                <StyledTableCell align="center">
                                                    {role?.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {role?.description ?? '-'}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {
                                                        Object.entries(role?.permissions).map(([i, permission]) => (
                                                            <Chip
                                                                label={permission}
                                                                key={i}
                                                                size="small"
                                                                className="mb-1 mr-1"
                                                            />
                                                        ))
                                                    }
                                                    {
                                                        role?.permissions?.length === 0 && 'Nenhuma permissão'
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Link
                                                        underline="none"
                                                        component="button"
                                                        onClick={() => {
                                                            setShowUsers({id: role?.id, name: role?.name});
                                                        }}
                                                    >
                                                        {role?.users_count} usuário{role?.users_count !== 1 && 's'}
                                                    </Link>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Tooltip title="Editar">
                                                        <IconButton
                                                            aria-label="Editar"
                                                            color="warning"
                                                            onClick={() => {
                                                                const permissions = Object.keys(role?.permissions);

                                                                setRole({
                                                                    id: role?.id,
                                                                    name: role?.name,
                                                                    description: role?.description,
                                                                    permissions,
                                                                });
                                                            }}
                                                        >
                                                            <EditIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Apagar">
                                                        <IconButton
                                                            aria-label="Apagar"
                                                            color="error"
                                                            onClick={() => confirmDelete(role?.id, role?.name)}
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )))
                                    )
                            }
                            {
                                !loading && !roles?.data?.length && (
                                    <TableRow>
                                        <TableCell colSpan={5} align="center">
                                            <Alert severity="warning">
                                                Nenhuma função cadastrada.
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={5}
                                    rowsPerPage={perPage}
                                    setPerPage={setPerPage}
                                    page={page}
                                    setPage={setPage}
                                    count={roles?.meta?.total ?? 0}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={Boolean(showConfirmDelete?.id)}
                onClose={() => setShowConfirmDelete(false)}
                fullScreen={fullScreen}
            >
                <DialogTitle>
                    <Box>
                        Excluir a função <span className="font-bold">{showConfirmDelete?.name}</span>?
                    </Box>
                    <BtnClose onClick={() => setShowConfirmDelete(false)}/>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        A exclusão dessa função removerá as permissões de todos os usuários associados a ela.
                    </DialogContentText>
                    <div className="text-center font-bold mt-3">
                        ESTA AÇÃO NÃO PODERÁ SER DESFEITA!
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        variant="outlined"
                        onClick={() => setShowConfirmDelete(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={deleteRole}
                    >
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>
            <CreateOrEditRole
                role={role}
                onClose={() => setRole(false)}
                fullScreen={fullScreen}
                loadRoles={loadRoles}
            />
            <ListUserFolder
                showUsers={showUsers}
                setShowUsers={setShowUsers}
            />
        </>
    );
}

export default Roles;

export {Roles};