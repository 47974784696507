import {createRoot} from 'react-dom/client';
import RouteList from './RouteList';
import reportWebVitals from './reportWebVitals';
import {AlertsProvider, AuthProvider, FirebaseProvider, TitleProvider, TreeProvider} from './services';
import {CookiesProvider} from 'react-cookie'
import {createTheme, responsiveFontSizes, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import './styles/index.css';

const root = createRoot(document.getElementById('root'));

let theme = createTheme({
    palette: {
        primary: {
            light: '#0874BB',
            main: '#004881',
            dark: '#014882',
            gradient: 'linear-gradient(to right, #004881, #0874BB)',
            contrastText: '#fff',
        },
        background: {
            grey: '#F5F4F4',
        },
        action: {
            gradient: {
                hover: 'linear-gradient(to right, rgba(1, 72, 130, .15), rgba(8, 116, 187, .05))',
                selected: 'linear-gradient(to right, rgba(1, 72, 130, .3), rgba(8, 116, 187, .1))',
            },
        },
        success: {
            100: '#82E27A',
            200: '#39D42B',
            500: '#259D1B',
            main: '#259D1B',
        },
        error: {
            100: '#FFC6C6',
            200: '#FE8383',
            500: '#DE4848',
            main: '#DE4848',
        },
        warning: {
            500: '#FFBF34',
            main: '#FFBF34',
        },
    },
    shape: {
        borderRadius: '0.5rem',
    },
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                },
            },
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                color: 'primary',
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: '0.5rem',
                    paddingLeft: '1.75rem',
                    paddingRight: '1.75rem',
                },
            },
        },
        MuiLoadingButton: {
            defaultProps: {
                variant: 'contained',
                color: 'primary',
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: '0.5rem',
                    paddingLeft: '1.75rem',
                    paddingRight: '1.75rem',
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '0.75rem',
                }
            },
        },
        MuiPaper: {
            styleOverrides: {
                elevation1: {
                    boxShadow: '0 2px 40px rgba(0, 0, 0, 0.05)',
                }
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '0.75rem',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    paddingTop: 12,
                    paddingRight: 12,
                    paddingBottom: 12,
                },
            },
        },
    },
    typography: {
        button: {
            textTransform: 'none',
        },
        fontFamily: 'Poppins, sans-serif',
    },
});

theme = responsiveFontSizes(theme);

root.render(
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
            <TitleProvider>
                <FirebaseProvider>
                    <AlertsProvider>
                        <CookiesProvider>
                            <AuthProvider>
                                <TreeProvider>
                                    <RouteList/>
                                </TreeProvider>
                            </AuthProvider>
                        </CookiesProvider>
                    </AlertsProvider>
                </FirebaseProvider>
            </TitleProvider>
        </ThemeProvider>
    </StyledEngineProvider>
);

reportWebVitals(console.log);