import {useAuth} from '../hooks';
import Grid from '@mui/material/Grid2';
import getItemIds from '../utils/getItemIds';
import {RichTreeView} from '@mui/x-tree-view';
import {useEffect, useState} from 'react';
import {StyledTreeItemGoTo} from './tree/StyledTreeItemGoTo';
import {Button, Divider, Drawer, IconButton, Tooltip, Typography, useTheme} from '@mui/material';
import DrawerHeader from './DrawerHeader';
import CloseIcon from '@mui/icons-material/Close';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import DisabledByDefaultOutlinedIcon from '@mui/icons-material/DisabledByDefaultOutlined';

function SelectWorkspace({selectWorkspace, setSelectWorkspace}) {
    const theme = useTheme();
    const {treeWorkspaces} = useAuth();
    const [allItems, setAllItems] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const handleSelectClose = () => setSelectWorkspace(false);
    const handleExpandAll = () => setExpanded(expanded.length  >= allItems.length ? [] : allItems);

    useEffect(() => {
        setAllItems(getItemIds(treeWorkspaces));
        setExpanded(treeWorkspaces.map(folder => `folder-${folder?.id}`));
        // eslint-disable-next-line
    }, [treeWorkspaces]);

    return (
        <Grid component="aside" aria-label="menu" className="bg-gradient-to-br from-neutral-100">
            <Drawer
                variant="temporary"
                open={selectWorkspace}
                onClose={handleSelectClose}
                anchor="right"
                sx={{
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        [theme.breakpoints.up('sm')]: {
                            width: 600,
                        },
                        [theme.breakpoints.up('lg')]: {
                            width: 900,
                        },
                        width: '100%',
                        boxSizing: 'border-box',
                    },
                }}
            >
                <DrawerHeader>
                    <Typography color="inherit" className="grow text-center font-medium text-xl">
                        Painel de Controle
                    </Typography>
                    <Tooltip title="Cancelar">
                        <IconButton onClick={handleSelectClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Tooltip>
                </DrawerHeader>
                <Divider/>
                <Grid container justifyContent="end" className="p-3">
                    <Button
                        variant="outlined"
                        startIcon={allItems.length === expanded.length ? <UnfoldLessIcon/> : <UnfoldMoreIcon/>}
                        onClick={handleExpandAll}
                        disabled={allItems.length === 0}
                    >
                        {allItems.length === expanded.length ? 'Recolher' : 'Expandir'}
                    </Button>
                </Grid>
                <Grid container className="p-3">
                    <RichTreeView
                        disableSelection
                        aria-label="Painel de Controle"
                        className="w-full overflow-auto"
                        expandedItems={expanded}
                        onExpandedItemsChange={(event, itemIds) => setExpanded(itemIds)}
                        items={treeWorkspaces ?? []}
                        getItemId={item => `${item.type}-${item.id}`}
                        getItemLabel={item => item.name}
                        slots={{
                            item: StyledTreeItemGoTo,
                            expandIcon: AddBoxOutlinedIcon,
                            collapseIcon: IndeterminateCheckBoxOutlinedIcon,
                            endIcon: () => <DisabledByDefaultOutlinedIcon className="opacity-30"/>,
                        }}
                    />
                </Grid>
            </Drawer>
        </Grid>
    );
}

export default SelectWorkspace;

export {SelectWorkspace};