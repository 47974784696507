import {ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import {useContext} from 'react';
import {TreeContext} from '../services';
import {AssociateMonitor, CreateOrEditAsset} from './';
import AirIcon from '@mui/icons-material/Air';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import WaterDropIcon from '@mui/icons-material/WaterDropOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import useAuth from '../hooks/useAuth';

function MenuNewAsset() {
    const {can} = useAuth();
    const {menu, setManageAsset, setManageFolder, setMenu, setAssociateMonitor, setDuplicateAsset} = useContext(TreeContext);
    const {type, root, id} = menu?.data ?? {};
    const isRoot = type === 'folder' && root === id;
    const canCreate = can('create_subfolders_assets', root);

    const handleCreate = type => {
        setManageAsset({...menu?.data, variant: type});
        setMenu(false);
    }

    const handleAssociate = type => {
        setAssociateMonitor({...menu.data, variant: type});
        setMenu(false);
    }

    const handleDuplicate = () => {
        setDuplicateAsset({...menu.data});
        setMenu(false);
    };

    return (
        <>
            <Menu
                id="menu-new-asset"
                anchorEl={menu?.anchorEl}
                open={menu?.type === 'create'}
                onClose={() => setMenu(false)}
            >
                {
                    (canCreate && type !== 'folder') && (
                        <MenuItem onClick={handleDuplicate}>
                            <ListItemIcon>
                                <ContentCopyIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Duplicar
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {
                    canCreate && type === 'folder' && !isRoot && (
                        <MenuItem onClick={() => {
                            setManageFolder({id: menu.data.id, action: 'duplicate'})
                            setMenu(false);
                        }}>
                            <ListItemIcon>
                                <ContentCopyIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Duplicar
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {
                    canCreate && type === 'folder' && (
                        <MenuItem onClick={() => {
                            setManageFolder({parent: menu.data.id, action: 'create'});
                            setMenu(false);
                        }}>
                            <ListItemIcon>
                                <FolderIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Nova pasta
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {
                    canCreate && type === 'folder' && (
                        <MenuItem onClick={() => handleCreate('machine')}>
                            <ListItemIcon>
                                <PrecisionManufacturingIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Nova máquina
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {
                    canCreate && type === 'machine' && (
                        <MenuItem onClick={() => handleCreate('subset')}>
                            <ListItemIcon>
                                <SettingsIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Novo subconjunto
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {
                    canCreate && type === 'subset' && (
                        <MenuItem onClick={() => handleCreate('component')}>
                            <ListItemIcon>
                                <RadioButtonCheckedIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Novo componente
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {
                    type !== 'folder' && can('install_monitor_assets', root) && (
                        <MenuItem onClick={() => handleAssociate('lubricator')}>
                            <ListItemIcon>
                                <WaterDropIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Associar lubrificador
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {
                    type !== 'folder' && can('install_monitor_assets', root) && (
                        <MenuItem onClick={() => handleAssociate('breather')}>
                            <ListItemIcon>
                                <AirIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Associar respiro
                            </ListItemText>
                        </MenuItem>
                    )
                }
                {
                    type !== 'folder' && can('install_monitor_assets', root) && (
                        <MenuItem onClick={() => handleAssociate('level-display')}>
                            <ListItemIcon>
                                <DeviceThermostatIcon fontSize="small"/>
                            </ListItemIcon>
                            <ListItemText>
                                Associar visor de nível
                            </ListItemText>
                        </MenuItem>
                    )
                }
            </Menu>
            <CreateOrEditAsset/>
            <AssociateMonitor/>
        </>
    );
}

export default MenuNewAsset;

export {MenuNewAsset};