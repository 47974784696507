import {useContext, useEffect, useState} from 'react';
import {useAuth, useTitle} from '../hooks';
import {
    Alert,
    Box,
    Breadcrumbs,
    Card,
    Chip,
    CircularProgress,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
    useTheme
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {ListSubareas, RefreshedAt} from '../components';
import {AlertContext, api} from '../services';
import {LineChart, PieChart} from '@mui/x-charts';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

function Dashboard() {
    const theme = useTheme();
    const {setTitle} = useTitle();
    const {workspace, setWorkspace, handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(true);
    const [dashboard, setDashboard] = useState({});
    const [health, setHealth] = useState(null);
    const [dates, setDates] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = id => setWorkspace(id);
    const handleMenu = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const icon = {
        null: <HomeIcon sx={{mr: 0.5}} fontSize="small"/>,
        true: <CorporateFareIcon sx={{mr: 0.5}} fontSize="small"/>,
        false: <FolderIcon sx={{mr: 0.5}} fontSize="small"/>,
    };

    function loadDashboard() {
        setLoading(true)

        api
            .get('/dashboard', {
                params: {
                    workspace,
                }
            })
            .then(response => setDashboard(response.data?.data))
            .catch(error => {
                if (error.response?.status === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao carregar o dashboard. Tente novamente mais tarde.');
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => setTitle('Página inicial'), [setTitle]);

    // eslint-disable-next-line
    useEffect(loadDashboard, [workspace]);

    useEffect(() => {
        if (!('monitors' in dashboard)) return;

        setDates(() => {
            let dates = [];

            for (let i = 6; i >= 0; i--) {
                const date = new Date();

                date.setDate(date.getDate() - i);

                dates.push(date.getDate() + '/' + (date.getMonth() + 1))
            }

            return dates;
        })

        if (!dashboard.monitors) {
            setHealth(null);
            return;
        }

        const percentage = (dashboard.monitors - dashboard.alerts.errors) / dashboard.monitors;

        setHealth((Math.ceil(percentage * 10000) / 100).toLocaleString());
    }, [dashboard]);

    if (loading) return <CircularProgress className="m-auto"/>;

    return (
        <Box className="self-start w-full">
            <Typography variant="caption">
                Você está em:
            </Typography>
            <Box className="my-2">
                <Breadcrumbs
                    aria-label="breadcrumb"
                    separator={<NavigateNextIcon sx={{marginBottom: '12px'}}/>}
                >
                    {
                        dashboard.location.map((folder, i) => (
                            <Chip
                                key={i}
                                icon={icon[folder.is_root]}
                                label={folder.name}
                                className="mb-3"
                                onClick={
                                    Object.entries(dashboard.options).length > 0 && dashboard.location.length - 1 === i ?
                                        handleMenu :
                                        () => setWorkspace(folder.id)
                                }
                            />
                        ))
                    }
                </Breadcrumbs>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {
                        Object.entries(dashboard.options).map(([i, folder]) => (
                            <MenuItem key={folder.id} onClick={() => handleClick(folder.id)}>
                                <ListItemIcon>
                                    {
                                        folder.id_root ?
                                            <CorporateFareIcon/> :
                                            <FolderIcon/>
                                    }
                                </ListItemIcon>
                                <ListItemText>{folder.name}</ListItemText>
                            </MenuItem>
                        ))
                    }
                </Menu>
            </Box>
            <Grid container spacing={3}>
                <Grid container size={12}>
                    <Typography className="flex items-center w-1/3 font-medium text-lg">
                        Visão geral
                    </Typography>
                    <RefreshedAt
                        className="self-start"
                        hour={dashboard.hour}
                        refresh={loadDashboard}
                    />
                </Grid>
                {
                    !dashboard.monitors && (
                        <Grid size={{xs: 12}}>
                            <Alert severity="warning">
                                Essa área não possui equipamentos associados.
                            </Alert>
                        </Grid>
                    )
                }
                <Grid size={{xs: 12, lg: 4}}>
                    <Card className="p-0 flex justify-center items-center flex-col h-full">
                        <Typography className="font-medium text-lg text-center mt-6">
                            Saúde Total
                        </Typography>
                        <PieChart
                            width={300}
                            height={320}
                            series={[
                                {
                                    data: [
                                        {
                                            label: 'Normal',
                                            value: dashboard.monitors - dashboard.alerts.errors,
                                            color: theme.palette.success.main,
                                        },
                                        {
                                            label: 'Com Falha',
                                            value: dashboard.alerts.errors,
                                            color: theme.palette.error.main,
                                        },
                                    ],
                                    innerRadius: 72,
                                    outerRadius: 104,
                                    paddingAngle: 0,
                                    cornerRadius: 0,
                                    cx: 150,
                                    cy: 125,
                                }
                            ]}
                            slotProps={{
                                legend: {
                                    itemGap: 30,
                                    direction: 'row',
                                    position: {
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    },
                                }
                            }}
                        >
                            <text
                                x={146 - (health?.length ?? 0) * 5.5}
                                y={131}
                                className="font-semibold text-2xl"
                            >
                                {
                                    health === null ?
                                        '-' :
                                        health + '%'
                                }
                            </text>
                            <text x={135} y={153} className="text-sm" style={{fill: '#CACACA'}}>
                                Saúde
                            </text>
                        </PieChart>
                    </Card>
                </Grid>
                <Grid size={{xs: 12, lg: 8}}>
                    <Card className="rounded-xl p-0 flex justify-center items-center flex-col">
                        <Typography className="font-medium text-lg text-center mt-6">
                            Diagnóstico de Falhas
                        </Typography>
                        <LineChart
                            height={350}
                            series={[
                                {
                                    type: 'line',
                                    color: theme.palette.error.main,
                                    label: 'Ativos com Falhas',
                                    data: dashboard.history.errors,
                                },
                            ]}
                            slotProps={{legend: {hidden: true}}}
                            yAxis={[{max: Math.ceil(Math.max.apply(null, dashboard.history.errors) * 1.20)}]}
                            {
                                ...(dates.length !== 0 && {xAxis: [{scaleType: 'point', data: dates}]})
                            }
                        />
                    </Card>
                </Grid>
            </Grid>
            <ListSubareas subareas={dashboard.areas}/>
        </Box>
    );
}

export default Dashboard;

export {Dashboard};