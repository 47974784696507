import {useContext, useEffect} from 'react';
import {AlertContext} from '../services';
import {Card, CardHeader, Divider,} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {ActiveSessions, PasswordChange, UpdateAccount} from '../components';
import {useAuth, useTitle} from '../hooks';

function MyAccount() {
    const {setTitle} = useTitle();
    const {handleLogout, user} = useAuth();
    const {newAlert} = useContext(AlertContext);

    useEffect(() => setTitle('Minha conta'), [setTitle]);

    return (
        <Grid
            container
            className="self-start justify-center"
            spacing={3}
        >
            <Grid size={{xs: 12, md: 6}}>
                <Card>
                    <CardHeader
                        subheader="Detalhes da conta"
                        subheaderTypographyProps={{color: 'text.primary'}}
                    />
                    <Divider/>
                    <UpdateAccount
                        newAlert={newAlert}
                    />
                </Card>
            </Grid>
            <Grid size={{xs: 12, md: 6}}>
                <Card>
                    <CardHeader
                        subheader="Acesso a conta"
                        subheaderTypographyProps={{color: 'text.primary'}}
                    />
                    <Divider/>
                    <PasswordChange
                        newAlert={newAlert}
                        handleLogout={handleLogout}
                        email={user.email}
                    />
                </Card>
            </Grid>
            <Grid size={{xs: 12, md: 9, lg: 6}}>
                <Card>
                    <CardHeader
                        subheader="Sessões ativas"
                        subheaderTypographyProps={{color: 'text.primary'}}
                    />
                    <Divider/>
                    <ActiveSessions/>
                </Card>
            </Grid>
        </Grid>
    );
}

export default MyAccount;

export {MyAccount};