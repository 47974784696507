import {useContext, useEffect, useState} from 'react';
import {useAuth, useTitle} from '../hooks';
import {AlertContext, api} from '../services';
import {
    BtnClose,
    InputSearch,
    ListFolderRole,
    StyledTableCell,
    StyledTableRow,
    TablePagination,
    TableSortLabel,
} from '../components';
import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {Link as LinkRoute} from 'react-router-dom';
import {phoneMask} from '../utils/phoneMask';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelIcon from '@mui/icons-material/CancelOutlined';

function ListUsers() {
    const {setTitle} = useTitle();
    const theme = useTheme();
    const {user, handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [users, setUsers] = useState({});
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [query, setQuery] = useState('');
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const [showFolders, setShowFolders] = useState(false);

    function confirmDelete(id, name) {
        if (id === user?.id) {
            newAlert('Você não pode excluir seu próprio usuário.');
            return;
        }

        setShowConfirmDelete({id, name});
    }

    function deleteUser() {
        api
            .delete(`/users/${showConfirmDelete?.id}`)
            .then(response => {
                newAlert(response?.data?.message, 'success');
                setUsers(users => {
                    const newUsers = {...users};

                    newUsers.data = newUsers.data.filter(user => user.id !== showConfirmDelete?.id);
                    newUsers.meta.total--;

                    return newUsers;
                });
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao tentar excluir o usuário. Tente novamente mais tarde.');
            })
            .finally(() => setShowConfirmDelete(false));
    }

    useEffect(() => setTitle('Usuários'), [setTitle]);

    useEffect(() => {
        setLoading(true);

        api
            .get('/users', {
                params: {
                    page: page + 1,
                    per_page: perPage,
                    order,
                    order_by: orderBy,
                    query,
                }
            })
            .then(response => setUsers(response.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    setUsers({});
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar usuários. Tente novamente mais tarde.');
                }
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, [page, perPage, query, order, orderBy]);

    return (
        <>
            <Box className="self-start text-center w-full">
                <LinkRoute to="/users/new">
                    <Button className="w-9/12">
                        <AddIcon/>
                        &nbsp;Novo Usuário
                    </Button>
                </LinkRoute>
            </Box>
            <Box className="self-start w-full">
                <InputSearch
                    setQuery={setQuery}
                    setPage={setPage}
                    placeholder="Pesquisar por id, nome ou e-mail..."
                />
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table" sx={{minWidth: 250}} size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    [
                                        {label: 'id', text: 'ID', short: true},
                                        {label: 'name', text: 'Nome', short: true},
                                        {label: 'email', text: 'E-mail', short: true},
                                        {label: 'phone', text: 'Telefone', short: true},
                                        {label: 'is_admin', text: 'Administrador', short: true},
                                        {label: 'folders_count', text: 'Pastas', short: true},
                                        {label: 'actions', text: 'Ações', short: false},
                                    ].map(({label, text, short}) => (
                                        <TableSortLabel
                                            key={label}
                                            label={label}
                                            text={text}
                                            short={short}
                                            order={order}
                                            setOrder={setOrder}
                                            orderBy={orderBy}
                                            setOrderBy={setOrderBy}
                                        />
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading ? (
                                        <TableRow>
                                            <TableCell colSpan={7} align="center">
                                                <CircularProgress className="m-auto my-3"/>
                                            </TableCell>
                                        </TableRow>
                                    ) :
                                    (
                                        (users && users?.data?.map(user => (
                                            <StyledTableRow key={user?.id}>
                                                <StyledTableCell align="center">
                                                    {user?.id}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {user?.name}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Box className="flex items-center justify-center">
                                                        <Tooltip
                                                            title={
                                                                user.email_notifications ?
                                                                    'Aceitou receber notificações' :
                                                                    'Não aceitou receber notificações'
                                                            }
                                                        >
                                                            {
                                                                user.email_notifications ?
                                                                    <CheckCircleIcon className="mr-1"
                                                                                     sx={{color: 'success.main'}}/> :
                                                                    <CancelIcon className="mr-1"
                                                                                sx={{color: 'error.main'}}/>
                                                            }
                                                        </Tooltip>
                                                        {user?.email}
                                                    </Box>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {user?.phone ? (
                                                        <Box className="flex items-center justify-center">
                                                            <Tooltip
                                                                title={
                                                                    user.phone_notifications ?
                                                                        'Aceitou receber notificações' :
                                                                        'Não aceitou receber notificações'
                                                                }
                                                            >
                                                                {
                                                                    user.phone_notifications ?
                                                                        <CheckCircleIcon className="mr-1"
                                                                                         sx={{color: 'success.main'}}/> :
                                                                        <CancelIcon className="mr-1"
                                                                                    sx={{color: 'error.main'}}/>
                                                                }
                                                            </Tooltip>
                                                            {phoneMask(user.phone)}
                                                        </Box>
                                                    ) : '-'}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {user?.is_admin ? 'Sim' : 'Não'}
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    {
                                                        user?.is_admin ?
                                                            'Todas' :
                                                            (
                                                                <Link
                                                                    underline="none"
                                                                    component="button"
                                                                    onClick={() => {
                                                                        setShowFolders({
                                                                            id: user?.id,
                                                                            name: user?.name
                                                                        });
                                                                    }}
                                                                >
                                                                    {user?.folders_count} pasta{user?.folders_count !== 1 && 's'}
                                                                </Link>
                                                            )
                                                    }
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Tooltip title="Editar">
                                                        <LinkRoute to={`/users/${user?.id}/edit`}>
                                                            <IconButton
                                                                aria-label="Editar"
                                                                color="warning"
                                                            >
                                                                <EditIcon/>
                                                            </IconButton>
                                                        </LinkRoute>
                                                    </Tooltip>
                                                    <Tooltip title="Apagar">
                                                        <IconButton
                                                            aria-label="Apagar"
                                                            color="error"
                                                            onClick={() => confirmDelete(user?.id, user?.name)}
                                                        >
                                                            <DeleteIcon/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )))
                                    )
                            }
                            {
                                !loading && !users?.data?.length && (
                                    <TableRow>
                                        <TableCell colSpan={7} align="center">
                                            <Alert severity="warning">
                                                Nenhum usuário encontrado para os filtros informados.
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    colSpan={7}
                                    rowsPerPage={perPage}
                                    setPerPage={setPerPage}
                                    page={page}
                                    setPage={setPage}
                                    count={users?.meta?.total ?? 0}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={Boolean(showConfirmDelete?.id)}
                onClose={() => setShowConfirmDelete(false)}
                fullScreen={fullScreen}
            >
                <DialogTitle>
                    <Box>
                        Excluir o usuário <span className="font-bold">{showConfirmDelete?.name}</span>?
                    </Box>
                    <BtnClose onClick={() => setShowConfirmDelete(false)}/>
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        A exclusão desse usuário removerá todos os dados associados a ele.
                    </DialogContentText>
                    <div className="text-center font-bold mt-3">
                        ESTA AÇÃO NÃO PODERÁ SER DESFEITA!
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        variant="outlined"
                        onClick={() => setShowConfirmDelete(false)}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={deleteUser}
                    >
                        Deletar
                    </Button>
                </DialogActions>
            </Dialog>
            <ListFolderRole
                showFolders={showFolders}
                setShowFolders={setShowFolders}
            />
        </>
    );
}

export default ListUsers;

export {ListUsers};