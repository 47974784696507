import {
    Avatar,
    Backdrop,
    Box,
    CircularProgress,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {AppBar, Aside, Notifications} from './';
import {useAuth, useTitle} from '../hooks';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';


function Header({open, openMobile, handleDrawerToggle, handleDrawerClose, handleDrawerTransitionEnd, isMobile}) {
    const {authenticated, handleLogout, user, loadingBackdrop, setLoadingBackdrop} = useAuth();
    const {title} = useTitle();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);

    const handleClickLogout = () => {
        handleClose();
        setLoadingBackdrop(true)
        handleLogout(true, () => setLoadingBackdrop(false));
    }

    useEffect(() => {
        if (!authenticated) return;

        if (!isMobile) handleDrawerToggle();
        // eslint-disable-next-line
    }, [authenticated, isMobile]);

    return (
        <>
            <AppBar
                position="sticky"
                sx={{background: 'primary.gradient',}}
                open={open}
            >
                <Toolbar className="pr-0 sm:pr-1">
                    {
                        authenticated && (
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="menu"
                                className="mr-2"
                                onClick={handleDrawerToggle}
                            >
                                <MenuIcon/>
                            </IconButton>
                        )
                    }
                    <Typography variant="h6" component="h1" className="grow">
                        <Link
                            to="/"
                            className="no-underline text-white"
                        >
                            {title}
                        </Link>
                    </Typography>
                    {
                        authenticated && (
                            <Box>
                                <Notifications/>
                                <Tooltip title={user?.name}>
                                    <IconButton
                                        size="large"
                                        aria-label="Abrir menu"
                                        aria-controls="menu-appbar"
                                        aria-haspopup="true"
                                        onClick={handleMenu}
                                        color="inherit"
                                    >
                                        <Avatar sx={{bgcolor: 'background.default', color: 'primary.light'}}>
                                            <PersonOutlineTwoToneIcon/>
                                        </Avatar>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                >
                                    <Link
                                        to="/my-account"
                                        style={{
                                            textDecoration: 'none',
                                            color: 'inherit',
                                        }}
                                    >
                                        <MenuItem onClick={handleClose}>
                                            <ListItemIcon>
                                                <AccountCircleIcon fontSize="small"/>
                                            </ListItemIcon>
                                            <ListItemText>Minha Conta</ListItemText>
                                        </MenuItem>
                                    </Link>
                                    <MenuItem onClick={handleClickLogout}>
                                        <ListItemIcon>
                                            <LogoutIcon fontSize="small"/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            Logout
                                        </ListItemText>
                                    </MenuItem>
                                </Menu>
                            </Box>
                        )
                    }
                </Toolbar>
            </AppBar>
            {
                authenticated && (
                    <Aside
                        isMobile={isMobile}
                        open={open}
                        openMobile={openMobile}
                        handleDrawerClose={handleDrawerClose}
                        handleDrawerTransitionEnd={handleDrawerTransitionEnd}
                    />
                )
            }
            <Backdrop
                className="text-white"
                open={loadingBackdrop}
                sx={{zIndex: 2000,}}
            >
                <CircularProgress className="m-auto"/>
            </Backdrop>
        </>
    );
}

export default Header;

export {Header};