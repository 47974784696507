import {CardActions, CardContent, Checkbox, FormControlLabel, Link, TextField} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import {useState} from 'react';
import {api} from '../services';
import useAuth from '../hooks/useAuth';
import BtnShowPassword from './BtnShowPassword';
import {filterObject, phoneMask} from '../utils';

function UpdateAccount({newAlert}) {
    const {handleLogout, user, setUser, setLoadingBackdrop} = useAuth();
    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [disableUpdateAccountBtn, setDisableUpdateAccountBtn] = useState(false);
    const [changeEmail, setChangeEmail] = useState(false);

    function handleChange(event) {
        const id = event.target.id === 'phone_notifications' ? 'phone' : event.target.id;

        if (errors[id]) {
            setErrors(filterObject(errors, index => index !== id));
        }
    }

    function handlePhone(event) {
        const input = event.target;

        input.value = phoneMask(input.value);
    }

    function handleCancelChange() {
        setLoadingBackdrop(true);

        api.patch('/email/cancel-change')
            .then(response => {
                setUser(user => {
                    let newUser = {...user};

                    newUser.new_email = null;

                    return newUser
                });

                newAlert(response?.data?.message, 'success');
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar cancelar alteração. Tente novamente mais tarde.');
                }
            })
            .finally(setLoadingBackdrop(false));
    }

    function handleSubmit(event) {
        event.preventDefault();

        setDisableUpdateAccountBtn(true);

        const name = event?.target?.name?.value;
        const email = event?.target?.email?.value;
        const emailNotifications = event?.target?.email_notifications?.checked;
        const phone = event?.target?.phone?.value.replace(/[^0-9]/g, '');
        const phoneNotifications = event?.target?.phone_notifications?.checked;
        const password = event?.target?.password?.value;

        if (name.length < 3) {
            setErrors({
                name: 'O nome precisa ter pelo menos 3 caracteres.',
            });
            setDisableUpdateAccountBtn(false);
            return;
        }

        if (phone.length > 0 && phone.length < 11) {
            setErrors({
                phone: 'O telefone precisa ter 11 dígitos.',
            });
            setDisableUpdateAccountBtn(false);
            return;
        }

        if (phoneNotifications && !phone) {
            setErrors({
                phone: 'O telefone é obrigatório se deseja receber notificações.',
            });
            setDisableUpdateAccountBtn(false);
            return;
        }

        let data = {
            name,
            email_notifications: emailNotifications,
            phone,
            phone_notifications: phoneNotifications,
        };

        if (changeEmail) {
            data.email = email;
            data.password = password;
        }

        api.patch('/update-account', data)
            .then(response => {
                newAlert(response?.data?.message, 'success');

                setUser(user => {
                    let newUser = {...user};

                    newUser.name = name;
                    newUser.email_notifications = emailNotifications;
                    newUser.phone_notifications = phoneNotifications;
                    if (changeEmail) newUser.new_email = email;

                    return newUser
                });

                if (changeEmail) event.target.password.value = '';
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar atualizar informações. Tente novamente mais tarde.');
                }
            })
            .finally(() => setDisableUpdateAccountBtn(false));
    }

    return (
        <Grid
            component="form"
            className="p-2 mx-auto mb-auto"
            onSubmit={handleSubmit}
        >
            <CardContent>
                <TextField
                    name="name"
                    label="Nome"
                    variant="outlined"
                    margin="normal"
                    type="text"
                    defaultValue={user?.name}
                    onChange={handleChange}
                    required
                    fullWidth
                    autoFocus
                    error={Boolean(errors?.name)}
                    helperText={errors?.name}
                    autoComplete="name"
                />
                <TextField
                    name="email"
                    label="E-mail"
                    variant="outlined"
                    margin="normal"
                    type="email"
                    defaultValue={user?.email}
                    onChange={handleChange}
                    fullWidth
                    disabled={!changeEmail}
                    error={Boolean(errors?.email)}
                    helperText={
                        errors?.email ?? (
                            user?.new_email ?
                                (
                                    <>
                                        O e-mail {user?.new_email} está pendente de confirmação.&nbsp;
                                        <Link
                                            color="error"
                                            underline="none"
                                            component="button"
                                            type="button"
                                            onClick={handleCancelChange}
                                        >
                                            Cancelar alteração
                                        </Link>
                                    </>
                                ) :
                                null
                        )
                    }
                    autoComplete="username"
                />
                <Grid container justifyContent="space-between">
                    <FormControlLabel
                        control={
                            <Checkbox
                                id="email_notifications"
                                defaultChecked={user?.email_notifications}
                                onChange={handleChange}
                            />
                        }
                        label="Desejo receber notificações por e-mail."
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={changeEmail}
                                id="change-mail"
                                onChange={event => setChangeEmail(event.target.checked)}
                            />
                        }
                        label="Editar Email"
                    />
                </Grid>
                {
                    changeEmail && (
                        <TextField
                            name="password"
                            label="Senha atual"
                            variant="outlined"
                            margin="normal"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            required
                            onChange={handleChange}
                            error={Boolean(errors?.password)}
                            helperText={errors?.password}
                            autoComplete="current-password"
                            slotProps={{
                                input: {
                                    endAdornment: <BtnShowPassword setShow={setShowPassword} show={showPassword}/>,
                                },
                            }}
                        />
                    )
                }
                <TextField
                    name="phone"
                    label="Telefone"
                    variant="outlined"
                    margin="normal"
                    type="tel"
                    defaultValue={phoneMask(user?.phone)}
                    onChange={handleChange}
                    fullWidth
                    error={Boolean(errors?.phone)}
                    helperText={errors?.phone}
                    onKeyUp={handlePhone}
                    slotProps={{
                        htmlInput: {
                            maxLength: 15,
                        },
                    }}
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked={user?.phone_notifications}
                            id="phone_notifications"
                            onChange={handleChange}
                        />
                    }
                    label="Desejo receber notificações por WhatsApp ou SMS."
                />
            </CardContent>
            <CardActions className="p-3">
                <LoadingButton
                    type="submit"
                    loading={disableUpdateAccountBtn}
                    fullWidth
                    disabled={Object.keys(errors).length > 0}
                >
                    Salvar informações
                </LoadingButton>
            </CardActions>
        </Grid>
    );
}

export default UpdateAccount;

export {UpdateAccount};