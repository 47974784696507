import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {useAuth} from '../hooks';
import api from '../services/api';
import BtnClose from './BtnClose';
import {LoadingButton} from '@mui/lab';
import {AlertContext} from '../services';
import {useContext, useState} from 'react';


function ConfirmDisassociateUser({confirmDisassociate, setConfirmDisassociate, setData}) {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    function handleSend() {
        setLoading(true);

        api
            .delete(`/folders/${confirmDisassociate?.folder?.id}/users/${confirmDisassociate?.user?.id}`, {
                params: {
                    folder: confirmDisassociate?.folder?.id,
                    user: confirmDisassociate?.user?.id,
                }
            })
            .then(response => {
                newAlert(response?.data?.message, 'success');

                setConfirmDisassociate(false);
                setData(data => {
                    const newData = {...data};

                    newData?.data?.splice(confirmDisassociate?.index, 1);

                    return newData;
                });
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao tentar desassociar. Tente novamente mais tarde.');
            })
            .finally(() => setLoading(false));
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={Boolean(confirmDisassociate?.folder?.id)}
            onClose={() => setConfirmDisassociate(false)}
            fullScreen={fullScreen}
        >
            <DialogTitle>
                <Box>
                    Remover <span className="font-bold">{confirmDisassociate?.user?.name}</span> da&nbsp;
                    <span className="font-bold">{confirmDisassociate?.folder?.name}</span>
                </Box>
                <BtnClose onClick={() => setConfirmDisassociate(false)} />
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                   O usuário não terá mais acesso aos ativos contidos nela.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    autoFocus
                    variant="outlined"
                    onClick={() => setConfirmDisassociate(false)}
                >
                    Cancelar
                </Button>
                <LoadingButton
                    onClick={handleSend}
                    color="error"
                    loading={loading}
                >
                    Remover
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDisassociateUser;

export {ConfirmDisassociateUser};