import clsx from 'clsx';
import {Link} from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import {TreeContext} from '../../services';
import {forwardRef, useContext} from 'react';
import {Box, Button, IconButton, Tooltip, Typography} from '@mui/material';
import {TreeItem2Icon, TreeItem2Provider, useTreeItem2} from '@mui/x-tree-view';
import {
    CustomTreeItemRoot,
    CustomTreeItemContent,
    CustomTreeItemIconContainer,
    CustomTreeItemGroupTransition
} from './CustomTreeItem';
import AddIcon from '@mui/icons-material/Add';
import AirIcon from '@mui/icons-material/Air';
import CircleIcon from '@mui/icons-material/Circle';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import HomeIcon from '@mui/icons-material/HomeOutlined';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import WaterDropIcon from '@mui/icons-material/WaterDropOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturingOutlined';

const StyledTreeItem = forwardRef((props, ref) => {
    const {
        itemId,
        label,
        children,
        disabled,
        ...other
    } = props;

    const {
        getRootProps,
        getContentProps,
        getIconContainerProps,
        getLabelProps,
        getGroupTransitionProps,
        status,
        publicAPI,
    } = useTreeItem2({itemId, children, label, disabled, rootRef: ref});

    const {
        id,
        type,
        name,
        labelStatus,
        root,
    } = publicAPI.getItem(itemId);

    const {can} = useAuth();
    const statusColor = labelStatus === null ? 'warning.main' : labelStatus ? 'success.main' : 'error.main';
    const {isConfiguring, setMenu, setShowConfirmDisassociate} = useContext(TreeContext);
    const icons = {
        'folder': FolderIcon,
        'machine': PrecisionManufacturingIcon,
        'subset': SettingsIcon,
        'component': RadioButtonCheckedIcon,
        'lubricator': WaterDropIcon,
        'breather': AirIcon,
    }
    let LabelIcon = icons[type];

    if (id === 0) {
        LabelIcon = HomeIcon;
    }

    const item = (
        <TreeItem2Provider itemId={itemId}>
            <CustomTreeItemRoot {...getRootProps(other)} isConfiguring={isConfiguring}>
                <CustomTreeItemContent
                    {...getContentProps({
                        className: clsx('content', {
                            'Mui-expanded': status.expanded,
                            'Mui-focused': status.focused,
                            'Mui-disabled': status.disabled,
                        }),
                    })}
                >
                    <CustomTreeItemIconContainer className="mr-0" {...getIconContainerProps()}>
                        <TreeItem2Icon status={status}/>
                    </CustomTreeItemIconContainer>
                    <Box className="flex items-center p-0.5 pr-0">
                        <Box component={LabelIcon} color="text.secondary" className="mr-1" sx={{fontSize: '30px'}}/>
                        <Typography
                            {
                                ...getLabelProps({
                                    variant: 'body2',
                                    className: 'grow flex items-end font-medium'
                                })
                            }
                        />
                    </Box>
                    {
                        !isConfiguring && (
                            <Box color={statusColor} className="grow flex justify-end">
                                <CircleIcon className="m-1 text-3xl"/>
                            </Box>
                        )
                    }
                    {
                        isConfiguring && id !== 0 && (
                            <Box className="grow flex items-center justify-end">
                                {
                                    ['lubricator', 'breather'].includes(type) ? (
                                        can('remove_monitor_assets', root) && (
                                            <Tooltip title="Desassociar">
                                                <IconButton
                                                    className="p-1"
                                                    onClick={event => {
                                                        event.preventDefault();

                                                        setShowConfirmDisassociate({type, id});
                                                    }}
                                                >
                                                    <LinkOffIcon fontSize="inherit"/>
                                                </IconButton>
                                            </Tooltip>
                                        )
                                    ) : (
                                        <>
                                            {
                                                (can('update_subfolders_assets', root) || can('manage_user_folder', root) || type === 'folder') && (
                                                    <Tooltip title="Editar">
                                                        <IconButton
                                                            className="p-1"
                                                            onClick={event => {
                                                                event.stopPropagation();

                                                                setMenu({
                                                                    anchorEl: event.currentTarget,
                                                                    data: {type, id, name, root},
                                                                    type: 'edit',
                                                                });
                                                            }}
                                                        >
                                                            <SettingsIcon fontSize="inherit"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                )
                                            }
                                            {
                                                (can('create_subfolders_assets', root) || (can('install_monitor_assets', root) && type !== 'folder')) && (
                                                    <Tooltip title="Criar">
                                                        <Button
                                                            sx={{
                                                                width: 40,
                                                                height: 48,
                                                                padding: 0,
                                                                minWidth: 40,
                                                                borderTopLeftRadius: 0,
                                                                borderBottomLeftRadius: 0,
                                                                marginLeft: 1,
                                                            }}
                                                            onClick={event => {
                                                                event.stopPropagation();

                                                                setMenu({
                                                                    anchorEl: event.currentTarget,
                                                                    data: {type, id, root},
                                                                    type: 'create',
                                                                });
                                                            }}
                                                        >
                                                            <AddIcon/>
                                                        </Button>
                                                    </Tooltip>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </Box>
                        )
                    }
                </CustomTreeItemContent>
                {children && (
                    <CustomTreeItemGroupTransition {...getGroupTransitionProps()} />
                )}
            </CustomTreeItemRoot>
        </TreeItem2Provider>
    );

    if (['lubricator', 'breather'].includes(type)) {
        return (
            <Link to={`/${type}s/${id}`} className="no-underline">
                {item}
            </Link>
        );
    }

    return item;
});

export default StyledTreeItem;

export {StyledTreeItem};