import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {useAuth} from '../hooks';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import SquareIcon from '@mui/icons-material/Square';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

function ListSubareas({subareas}) {
    const {setWorkspace} = useAuth();
    const icon = {
        true: <CorporateFareIcon sx={{mr: 0.5}}/>,
        false: <FolderIcon sx={{mr: 0.5}} fontSize="small"/>,
    };

    return (
        <>
            <Typography variant="body1" className="my-5 font-medium">
                Total de Sub-Áreas: {subareas.length}
            </Typography>
            <Grid container spacing={3} size={12}>
                {
                    subareas.map(area => (
                        <Grid size={{xs: 12, sm: 6, md: 4, lg: 3}} key={area.id}>
                            <Card className="break-all py-3.5 px-6">
                                <CardHeader
                                    className="p-0"
                                    subheader={
                                        <>
                                            {icon[area.is_root]}
                                            {area.name}
                                        </>
                                    }
                                    subheaderTypographyProps={{
                                        color: 'text.primary',
                                        className: 'flex items-center text-sm',
                                    }}
                                />
                                <Divider className="my-5"/>
                                <CardContent className="p-0">
                                    <List className="p-0">
                                        <ListItem className="p-0">
                                            <ListItemIcon sx={{minWidth: '30px'}}>
                                                <SquareIcon
                                                    sx={{
                                                        color: area.alerts.errors ? 'error.main' : 'success.main',
                                                    }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {area.alerts.errors || 'Sem'}
                                                &nbsp;falha{area.alerts.errors !== 1 && 's'}
                                            </ListItemText>
                                        </ListItem>
                                    </List>
                                </CardContent>
                                <CardActions className="justify-center p-0 mt-8">
                                    <Button
                                        size="small"
                                        variant="outlined"
                                        onClick={() => setWorkspace(area.id)}
                                    >
                                        Detalhar
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))
                }
                {
                    subareas.length === 0 && (
                        <Grid size={12}>
                            <Alert severity="warning">
                                Essa área não possui sub-áreas.
                            </Alert>
                        </Grid>
                    )
                }
            </Grid>
        </>
    );
}

export default ListSubareas;

export {ListSubareas}