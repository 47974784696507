import {useEffect} from 'react';
import {List} from '@mui/material';
import {ListNotifications, TablePagination} from '../components';
import {useNotifications, useTitle} from '../hooks';

function Notifications() {
    const {setTitle} = useTitle();
    const {page, setPage, perPage, setPerPage, notifications} = useNotifications();

    useEffect(() => setTitle('Notificações'), [setTitle]);

    return (
        <List disablePadding className="w-full">
            <ListNotifications close={false}/>
            <TablePagination
                component="div"
                rowsPerPageOptions={[5, 10, 25, 50, 100]}
                rowsPerPage={perPage}
                setPerPage={setPerPage}
                page={page}
                setPage={setPage}
                count={notifications?.meta?.total ?? 0}
            />
        </List>
    );
}

export default Notifications;

export {Notifications}