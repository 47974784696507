import {ListItem as ListItemMUI, ListItemButton, ListItemIcon, ListItemText, styled} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';

const StyledListItemButton = styled(ListItemButton)(({theme}) => ({
    '&:hover': {
        background: theme.palette.action.gradient.hover,
    },
    '&.Mui-selected': {
        fontWeight: 600,
        background: theme.palette.action.gradient.selected,
    },
    '&:hover, &.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
        paddingLeft: 12,
        paddingRight: 16,
        color: theme.palette.primary.main,

        borderLeft: `4px solid ${theme.palette.primary.main}`,
    },
}));

function ListItem({text, Icon, route, regEx, onClick = () => null}) {
    const local = useLocation();
    const regex = new RegExp(regEx ?? route);

    return (
        <Link
            to={route}
            style={{textDecoration: 'none', color: 'inherit'}}
            onClick={onClick}
        >
            <ListItemMUI
                key={text}
                disablePadding
            >
                <StyledListItemButton selected={regex.test(local.pathname)}>
                    <ListItemIcon>
                        <Icon/>
                    </ListItemIcon>
                    <ListItemText primary={text} primaryTypographyProps={{fontWeight: 'inherit'}}/>
                </StyledListItemButton>
            </ListItemMUI>
        </Link>
    );
}

export default ListItem;

export {ListItem};