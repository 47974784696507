import {useEffect} from 'react';
import {useTitle} from '../hooks';
import {TableLevelDisplays} from '../components/TableLevelDisplays';

function ListLevelDisplays() {
    const {setTitle} = useTitle();

    useEffect(() => setTitle('Visores de Níveis'), [setTitle]);

    return <TableLevelDisplays/>;
}

export default ListLevelDisplays;

export {ListLevelDisplays};