import {Box, InputBase} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {useState} from 'react';
import {styled} from '@mui/system';

const Search = styled('form')(({theme}) => ({
    padding: theme.spacing(2),
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#fff',
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
}));

function InputSearch({setQuery, setPage, placeholder}) {
    const [search, setSearch] = useState('');
    const [timeoutSearch, setTimeoutSearch] = useState(null);

    const handleChangeSearch = event => setSearch(event.target.value);

    const handleTimeoutSearch = () => {
        clearTimeout(timeoutSearch);
        setTimeoutSearch(setTimeout(() => {
            setQuery(search);
            setPage(0);
        }, 1500));
    };

    const handleSearch = event => {
        event.preventDefault();

        setQuery(search);
        setPage(0);
    };

    return (
        <Box className="w-full">
            <Search
                onSubmit={handleSearch}
            >
                <Box className="flex items-center">
                    <SearchIcon/>
                    <InputBase
                        className="ml-2 grow"
                        placeholder={placeholder}
                        onChange={handleChangeSearch}
                        onKeyUp={handleTimeoutSearch}
                        value={search}
                        autoFocus
                    />
                </Box>
            </Search>
        </Box>
    );
}

export default InputSearch;

export {InputSearch};