import {BtnClose} from './index';
import {useAuth} from '../hooks';
import {LoadingButton} from '@mui/lab';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useState} from 'react';
import {
    Autocomplete,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    TextField,
    Typography
} from '@mui/material';
import {cepMask, cnpjMask, filterObject, uppercaseFirst} from '../utils';
import Grid from '@mui/material/Grid2';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function CreateOrEditFolder({folder, setFolder, fullScreen, loadFolders, type}) {
    const {can, handleLogout, setLoadingBackdrop} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [errors, setErrors] = useState({});
    const [id, setId] = useState(0);
    const [name, setName] = useState('');
    const [cnpj, setCnpj] = useState('');
    const [unit, setUnit] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [cep, setCep] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [loading, setLoading] = useState(false);
    const actions = {
        create: 'criar',
        edit: 'editar',
        show: 'detalhar',
        duplicate: 'duplicar',
    };

    const OPTIONS = [
        {
            label: 'São Paulo',
            code: 'SP',
        },
        {
            label: 'Rio de Janeiro',
            code: 'RJ',
        },
        {
            label: 'Espírito Santo',
            code: 'ES',
        },
        {
            label: 'Minas Gerais',
            code: 'MG',
        },
        {
            label: 'Bahia',
            code: 'BA',
        },
        {
            label: 'Distrito Federal',
            code: 'DF',
        },
        {
            label: 'Paraná',
            code: 'PR',
        },
        {
            label: 'Rio Grande do Sul',
            code: 'RS',
        },
        {
            label: 'Pernambuco',
            code: 'PE',
        },
        {
            label: 'Ceará',
            code: 'CE',
        },
        {
            label: 'Pará',
            code: 'PA',
        },
        {
            label: 'Santa Catarina',
            code: 'SC',
        },
        {
            label: 'Goáis',
            code: 'GO',
        },
        {
            label: 'Maranhão',
            code: 'MA',
        },
        {
            label: 'Paraíba',
            code: 'PB',
        },
        {
            label: 'Amazonas',
            code: 'AM'
        },
        {
            label: 'Mato Grosso',
            code: 'MT',
        },
        {
            label: 'Rio Grande do Norte',
            code: 'RN',
        },
        {
            label: 'Piauí',
            code: 'PI',
        },
        {
            label: 'Alagoas',
            code: 'AL',
        },
        {
            label: 'Mato Grosso do Sul',
            code: 'MS',
        },
        {
            label: 'Sergipe',
            code: 'SE',
        },
        {
            label: 'Rondônia',
            code: 'RO',
        },
        {
            label: 'Tocantins',
            code: 'TO',
        },
        {
            label: 'Acre',
            code: 'AC',
        },
        {
            label: 'Amapá',
            code: 'AP',
        },
        {
            label: 'Roraima',
            code: 'RR'
        },
    ];

    function reset() {
        if (folder?.id === id) return;

        setId('');
        setName('');
        setCnpj('');
        setCompanyName('');
        setUnit('');
        setStreet('');
        setNumber('');
        setComplement('');
        setNeighborhood('');
        setCity('');
        setState('');
        setCep('');
        setErrors({});
    }

    function handleCnpj(event) {
        const input = event.target;

        input.value = cnpjMask(input.value);
    }

    function handleCep(event) {
        const input = event.target;

        input.value = cepMask(input.value);
    }

    function handleChange(event, newValue) {
        const name = event.target.name;
        const value = event.target.value;
        
        if (name === 'name') setName(value);
        else if (name === 'cnpj') setCnpj(value);
        else if (name  === 'company_name') setCompanyName(value);
        else if (name  === 'unit') setUnit(value);
        else if (name  === 'street') setStreet(value);
        else if (name  === 'number') setNumber(value);
        else if (name === 'complement') setComplement(value);
        else if (name  === 'neighborhood') setNeighborhood(value);
        else if (name  === 'city') setCity(value);
        else if (name === undefined) setState(newValue);
        else if (name === 'cep') setCep(value);

        if (errors[name]) {
            setErrors(filterObject(errors, index => index !== name));
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        if (folder?.action === 'show') {
            setFolder({...folder, action: 'edit'});
            return;
        }

        const cepNumeric = cep.replace(/[^0-9]/g, '');
        const cnpjNumeric = cnpj.replace(/[^0-9]/g, '');

        if (name.length < 3) {
            setErrors({
                name: 'O nome precisa ter pelo menos 3 caracteres.',
            });
            return;
        }

        if (cepNumeric.length > 0 && cepNumeric.length < 8) {
            setErrors({
                cep: 'O cep precisa ter 8 dígitos.',
            });
            return;
        }

        if (cnpjNumeric.length > 0 && cnpjNumeric.length < 14) {
            setErrors({
                cnpj: 'O cnpj precisa ter 14 dígitos.',
            });
            return;
        }

        let apiMethod = api.post;
        let apiEndpoint = '/folders';
        let data = {
            name,
            cnpj: cnpjNumeric,
            company_name: companyName,
            unit,
            street,
            number,
            complement,
            neighborhood,
            city,
            state,
            cep: cepNumeric,
        };

        if (type === 'empresa') {
            apiEndpoint += '/root';
        } else if (folder?.action === 'create') {
            data.parent_type = 'folder';
            data.parent_id = folder?.parent;
        }

        if (folder?.id) {
            apiEndpoint += '/' + folder.id;

            if (folder?.action !== 'duplicate') {
                apiMethod = api.put;
            } else {
                apiEndpoint += '/duplicate';
            }
        }

        setLoading(true);

        apiMethod(apiEndpoint, data)
            .then(response => {
                const expand = folder?.parent ? `folder-${folder?.parent}` : '';

                newAlert(response?.data?.message, 'success');
                loadFolders(expand);
                setFolder(false);
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    const action = folder?.id ? 'editar' : 'criar';

                    newAlert(error.response?.data?.message ?? `Erro ao tentar ${action} a ${type}. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        reset();

        if (!folder?.id || folder?.action === 'create' || folder?.id === id) return;

        setLoadingBackdrop(true);

        api.get('/folders/' + folder?.id)
            .then(response => {
                response = response.data?.data;

                setId(response?.id ?? '');
                setName(response?.name ?? '');
                setCnpj(response?.cnpj ?? '');
                setCompanyName(response?.company_name ?? '');
                setUnit(response?.unit ?? '');
                setStreet(response?.street ?? '');
                setNumber(response?.number ?? '');
                setComplement(response?.complement ?? '');
                setNeighborhood(response?.neighborhood ?? '');
                setCity(response?.city ?? '');
                setState(response?.state ?? '');
                setCep(response?.cep ?? '');
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    newAlert(error.response?.data?.message ?? `Erro ao tentar carregar a ${type}. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoadingBackdrop(false));

        // eslint-disable-next-line
    }, [folder]);

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            open={Boolean(folder)}
            onClose={() => setFolder(false)}
            fullScreen={fullScreen}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
                noValidate: true,
            }}
        >
            <DialogTitle>
                <Box>
                    {folder?.id ? uppercaseFirst(actions[folder?.action]) : 'Nova'} {type}
                </Box>
                <BtnClose onClick={() => setFolder(false)} />
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3} rowSpacing={0}>
                    <Grid size={{md: 6, xs: 12}}>
                        <TextField
                            name="name"
                            label="Nome"
                            variant="outlined"
                            margin="normal"
                            type="text"
                            value={name}
                            onChange={handleChange}
                            required
                            fullWidth
                            autoFocus={folder?.action !== 'show'}
                            error={Boolean(errors?.name)}
                            helperText={errors?.name}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{md: 6, xs: 12}}>
                        <TextField
                            name="cnpj"
                            label="CNPJ"
                            variant="outlined"
                            margin="normal"
                            type="text"
                            value={cnpjMask(cnpj)}
                            onChange={handleChange}
                            onKeyUp={handleCnpj}
                            fullWidth
                            error={Boolean(errors?.cnpj)}
                            helperText={errors?.cnpj}
                            slotProps={{
                                htmlInput: {
                                    maxLength: 18,
                                },
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <TextField
                    name="company_name"
                    label="Razão Social"
                    variant="outlined"
                    margin="normal"
                    type="text"
                    value={companyName}
                    onChange={handleChange}
                    fullWidth
                    error={Boolean(errors?.company_name)}
                    helperText={errors?.company_name}
                    slotProps={{
                        input: {
                            readOnly: folder?.action === 'show',
                        },
                    }}
                />
                <TextField
                    name="unit"
                    label="Unidade"
                    variant="outlined"
                    margin="normal"
                    type="text"
                    value={unit}
                    onChange={handleChange}
                    fullWidth
                    error={Boolean(errors?.unit)}
                    helperText={errors?.unit}
                    slotProps={{
                        input: {
                            readOnly: folder?.action === 'show',
                        },
                    }}
                />
                <Typography
                    variant="subtitle1"
                    className="mt-3 mb-1"
                >
                    Endereço
                </Typography>
                <Divider className="mb-3"/>
                <Grid container spacing={3} rowSpacing={0}>
                    <Grid size={{md: 10, xs: 12}}>
                        <TextField
                            name="street"
                            label="Rua"
                            variant="outlined"
                            margin="normal"
                            type="text"
                            value={street}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors?.street)}
                            helperText={errors?.street}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{md: 2, xs: 12}}>
                        <TextField
                            name="number"
                            label="Número"
                            variant="outlined"
                            margin="normal"
                            type="text"
                            value={number}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors?.number)}
                            helperText={errors?.number}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{md: 6, xs: 12}}>
                        <TextField
                            name="neighborhood"
                            label="Bairro"
                            variant="outlined"
                            margin="normal"
                            type="text"
                            value={neighborhood}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors?.neighborhood)}
                            helperText={errors?.neighborhood}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{md: 6, xs: 12}}>
                        <TextField
                            name="complement"
                            label="Complemento"
                            variant="outlined"
                            margin="normal"
                            type="text"
                            value={complement}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors?.complement)}
                            helperText={errors?.complement}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={12}>
                        <TextField
                            name="city"
                            label="Cidade"
                            variant="outlined"
                            margin="normal"
                            type="text"
                            value={city}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors?.city)}
                            helperText={errors?.city}
                            slotProps={{
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                    <Grid size={{md: 6, xs: 12}}>
                        <Autocomplete
                            name="state"
                            className="mt-4"
                            value={state}
                            onChange={handleChange}
                            fullWidth
                            options={OPTIONS}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Estado"
                                    error={Boolean(errors?.state)}
                                    helperText={errors?.state}
                                    slotProps={{
                                        input: {
                                            readOnly: folder?.action === 'show',
                                        },
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    <Grid size={{md: 6, xs: 12}}>
                        <TextField
                            name="cep"
                            label="CEP"
                            variant="outlined"
                            margin="normal"
                            type="text"
                            value={cepMask(cep)}
                            onKeyUp={handleCep}
                            onChange={handleChange}
                            fullWidth
                            error={Boolean(errors?.cep)}
                            helperText={errors?.cep}
                            slotProps={{
                                htmlInput: {
                                    maxLength: 9,
                                },
                                input: {
                                    readOnly: folder?.action === 'show',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            {
                (folder?.action !== 'show' || can(folder?.root === folder?.id ? 'update_root_folder' : 'update_subfolders_assets', folder?.root)) && (
                    <DialogActions>
                        <LoadingButton
                            variant={folder?.action === 'show' ? 'outlined' : 'contained'}
                            startIcon={folder?.id ? folder?.action === 'duplicate' ? <ContentCopyIcon/> : <EditIcon/> : <AddIcon/>}
                            disabled={Object.keys(errors).length > 0}
                            fullWidth
                            type="submit"
                            loading={loading}
                        >
                            {folder?.id ? folder?.action === 'duplicate' ? 'Duplicar' : 'Editar' : 'Criar'} {type}
                        </LoadingButton>
                    </DialogActions>
                )
            }
        </Dialog>
    );
}

export default CreateOrEditFolder;

export {CreateOrEditFolder};