import {
    Alert,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuList,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {
    ConfirmDeleteMonitor,
    CreateOrEditMonitor,
    InputSearch,
    RefreshedAt,
    StyledTableCell,
    StyledTableRow,
    TablePagination,
    TableSortLabel,
} from '../components';
import {Link} from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';
import {AlertContext, api} from '../services';
import {useAuth} from '../hooks';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import VisibilityIcon from '@mui/icons-material/VisibilityOutlined'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturingOutlined';

function TableLubricators({manage = false}) {
    const theme = useTheme();
    const {workspace, handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [lubricators, setLubricators] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [filters, setFilters] = useState(['up', 'error']);
    const [showConfirmDelete, setShowConfirmDelete] = useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [lubricator, setLubricator] = useState(null);
    const [query, setQuery] = useState('');

    const handleMenu = event => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const confirmDelete = id => setShowConfirmDelete(id);

    const Icons = {
        component: RadioButtonCheckedIcon,
        subset: SettingsIcon,
        machine: PrecisionManufacturingIcon
    };

    function handleChange(type) {
        if (filters.length === 1 && filters[0] === type) return;

        if (filters.includes(type)) setFilters(filters.filter(filter => filter !== type));
        else setFilters([...filters, type]);
    }

    function loadLubricators() {
        setLoading(true);

        let url = '/lubricators';
        let params = {
            page: page + 1,
            per_page: perPage,
            order,
            order_by: orderBy,
            filters,
            query,
        }

        if (manage) url += '/all';
        else params['workspace'] = workspace;

        api
            .get(url, {params})
            .then(response => setLubricators(response.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else {
                    setLubricators({});
                    newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar lubrificadores. Tente novamente mais tarde.');
                }
            })
            .finally(() => setLoading(false));
    }

    // eslint-disable-next-line
    useEffect(loadLubricators, [page, perPage, workspace, order, orderBy, filters, query]);

    return (
        <>
            {
                manage && (
                    <Box className="self-start text-center w-full">

                        <Button className="w-9/12" onClick={() => setLubricator(true)}>
                            <AddIcon/>
                            &nbsp;Novo Lubrificador
                        </Button>
                    </Box>
                )
            }
            <Box className={(manage ? 'self-start ' : '') + 'w-full'}>
                <RefreshedAt
                    className="mt-3 mb-2"
                    hour={lubricators.hour}
                    refresh={loadLubricators}
                />
                <InputSearch
                    setQuery={setQuery}
                    setPage={setPage}
                    placeholder="Pesquisar por id, serial ou local de instalação ..."
                />
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table" sx={{minWidth: 250}} size="small">
                        <TableHead>
                            <TableRow>
                                {
                                    [
                                        {label: 'id', text: 'ID', short: true},
                                        {label: 'serial', text: 'Serial', short: true},
                                        {label: 'capacity', text: 'Capacidade', short: true},
                                        {label: 'lubricatable_type', text: 'Instalado na(o)', short: true},
                                        {label: 'alert_type', text: 'Status', short: false},
                                        {label: 'actions', text: 'Ações', short: false},
                                    ].map(({label, text, short}) => (
                                        <TableSortLabel
                                            key={label}
                                            label={label}
                                            text={text}
                                            short={short}
                                            order={order}
                                            setOrder={setOrder}
                                            orderBy={orderBy}
                                            setOrderBy={setOrderBy}
                                        />
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                loading ? (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">
                                                <CircularProgress className="m-auto my-3"/>
                                            </TableCell>
                                        </TableRow>
                                    ) :
                                    (
                                        (lubricators && lubricators?.data?.map(lubricator => {
                                            const Icon = Icons[lubricator?.lubricatable_type] ?? SettingsIcon;

                                            const status = lubricator?.status ? 'success.main' : 'error.main';

                                            return (
                                                <StyledTableRow key={lubricator?.id}>
                                                    <StyledTableCell align="center">
                                                        {lubricator?.id}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {lubricator?.serial}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {lubricator?.capacity.toLocaleString()}g
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <Box className="flex items-center justify-center">
                                                            {
                                                                lubricator?.lubricatable_type ? (
                                                                    <>
                                                                        <Icon className="mr-1"/>
                                                                        {lubricator?.lubricatable?.name}
                                                                    </>
                                                                ) : 'Não instalado'
                                                            }
                                                        </Box>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <Box color={status} component={CircleIcon}/>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <Tooltip title="Detalhar">
                                                            <Link to={`/lubricators/${lubricator?.id}`}>
                                                                <IconButton
                                                                    aria-label="Detalhar"
                                                                    color="primary"
                                                                >
                                                                    <VisibilityIcon/>
                                                                </IconButton>
                                                            </Link>
                                                        </Tooltip>
                                                        {
                                                            manage && (
                                                                <>
                                                                    <Tooltip title="Editar">
                                                                        <IconButton
                                                                            aria-label="Editar"
                                                                            color="warning"
                                                                            onClick={() => {
                                                                                setLubricator({
                                                                                    id: lubricator?.id,
                                                                                    serial: lubricator?.serial,
                                                                                    capacity: lubricator?.capacity,
                                                                                })
                                                                            }}
                                                                        >
                                                                            <EditIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                    <Tooltip title="Apagar">
                                                                        <IconButton
                                                                            aria-label="Apagar"
                                                                            color="error"
                                                                            onClick={() => confirmDelete(lubricator?.id)}
                                                                        >
                                                                            <DeleteIcon/>
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </>
                                                            )
                                                        }
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        }))
                                    )
                            }
                            {
                                !loading && !lubricators?.data?.length && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            <Alert severity="warning">
                                                Nenhum lubrificador encontrado para os filtros informados.
                                            </Alert>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                        <TableFooter>
                            <TableRow>
                                <TableCell>
                                    <Tooltip title="Filtrar">
                                        <IconButton
                                            aria-label="Filtrar"
                                            aria-controls="#filter-menu"
                                            onClick={handleMenu}
                                        >
                                            <FilterListIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Menu
                                        id="filter-menu"
                                        open={Boolean(anchorEl)}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                    >
                                        <MenuList>
                                            {
                                                [
                                                    {type: 'up', label: 'Normal', color: 'success.main'},
                                                    {type: 'error', label: 'Com Falha', color: 'error.main'},
                                                ].map(({type, label, color}, i) => (
                                                    <ListItem
                                                        key={i}
                                                        disablePadding
                                                    >
                                                        <ListItemButton
                                                            onClick={() => handleChange(type)}
                                                            dense
                                                        >
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    edge="start"
                                                                    checked={filters.indexOf(type) !== -1}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    disabled={filters.length === 1 && filters[0] === type}
                                                                    slotProps={{
                                                                        input: {
                                                                            'aria-labelledby': `filter-lubricators-${type}`,
                                                                        },
                                                                    }}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText id={`filter-lubricators-${type}`}>
                                                                <Box className="flex items-center justify-start">
                                                                    <CircleIcon sx={{color}} className="mr-3"/>
                                                                    {label}
                                                                </Box>
                                                            </ListItemText>
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))
                                            }
                                        </MenuList>
                                    </Menu>
                                </TableCell>
                                <TablePagination
                                    colSpan={6}
                                    rowsPerPage={perPage}
                                    setPerPage={setPerPage}
                                    page={page}
                                    setPage={setPage}
                                    count={lubricators?.meta?.total ?? 0}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Box>
            {
                manage && (
                    <>
                        <ConfirmDeleteMonitor
                            type="lubricator"
                            showConfirmDelete={showConfirmDelete}
                            onClose={() => setShowConfirmDelete(false)}
                            fullScreen={fullScreen}
                            setMonitors={setLubricators}
                        />
                        <CreateOrEditMonitor
                            type="lubricator"
                            monitor={lubricator}
                            onClose={() => setLubricator(false)}
                            fullScreen={fullScreen}
                            loadMonitors={loadLubricators}
                        />
                    </>
                )
            }
        </>
    );
}

export default TableLubricators;

export {TableLubricators};