import {api} from '../services';
import {useEffect, useState} from 'react';
import {Autocomplete, TextField} from '@mui/material';
import Grid from '@mui/material/Grid2';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

function FindCompany({company, setCompany, setFolder}) {
    const [query, setQuery] = useState('');
    const [search, setSearch] = useState('');
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [timeoutSearch, setTimeoutSearch] = useState(null);

    const handleDisableSubmit = event => event.keyCode === 13 && event.preventDefault() && setQuery(search);

    const handleTimeoutSearch = () => {
        clearTimeout(timeoutSearch);
        setTimeoutSearch(setTimeout(() => setQuery(search), 1000));
    };

    useEffect(() => {
        if (!query) {
            let newOptions = [];

            if (company) {
                newOptions = [company];
            }

            setOptions(newOptions);
            return undefined;
        }

        setLoading(true);

        api
            .get('/folders/search', {
                params: {
                    query,
                }
            })
            .then(response => {
                let newOptions = [];

                if (response.data) {
                    newOptions = [...response.data.data];
                }

                setOptions(newOptions);
            })
            .finally(() => setLoading(false));
    }, [company, query]);

    return (
        <Autocomplete
            autoComplete
            includeInputInList
            value={company}
            options={options}
            loading={loading}
            loadingText="Buscando ..."
            noOptionsText={query ? 'Nenhuma empresa encontrada' : 'Digite o nome ou id da empresa'}
            filterOptions={x => x}
            getOptionLabel={option => option.name}
            onInputChange={(event, value) => setSearch(value)}
            onChange={(event, value) => {
                setQuery('');
                setCompany(value);
                setFolder(null);
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Empresa"
                    fullWidth
                    onKeyUp={handleTimeoutSearch}
                    onKeyDown={handleDisableSubmit}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={props.key}>
                    <Grid container alignItems="center">
                        <Grid sx={{display: 'flex', width: 44}}>
                            <CorporateFareIcon/>
                        </Grid>
                        <Grid sx={{width: 'calc(100% - 44px)', wordWrap: 'break-word'}}>
                            {option.name}
                        </Grid>
                    </Grid>
                </li>
            )}
        />
    );
}

export default FindCompany;

export {FindCompany};