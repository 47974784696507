import {useContext, useEffect, useState} from 'react';
import api from '../services/api';
import {AlertContext} from '../services';

function useAuthProvider() {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const {newAlert} = useContext(AlertContext);
    const [workspace, setWorkspace] = useState(parseInt(localStorage.getItem('workspace') ?? 0));
    const [workspaces, setWorkspaces] = useState([]);
    const [treeWorkspaces, setTreeWorkspaces] = useState([]);
    const [selectWorkspace, setSelectWorkspace] = useState(false);
    const [loadingBackdrop, setLoadingBackdrop] = useState(false);

    function getWorkspace(user) {
        const list = user?.workspaces ?? {};
        const tree = user?.tree ?? [];

        delete user?.workspaces;
        delete user?.tree;

        return {list, tree};
    }

    function setWorkspacePersistent(value) {
        localStorage.setItem('workspace', value);
        setWorkspace(value);
    }

    useEffect(() => {
        const token = sessionStorage.getItem('token') || localStorage.getItem('token');

        if (token) {
            api.get('/user')
                .then(response => {
                    let user = response?.data?.data;
                    const {tree, list} = getWorkspace(user);

                    if (!(workspace in list)) setWorkspace(0);

                    setWorkspaces(list);
                    setTreeWorkspaces(tree);

                    setUser(user)
                    setAuthenticated(true);
                })
                .catch(() => handleLogout())
                .finally(() => setLoading(false));
        } else setLoading(false);
        // eslint-disable-next-line
    }, []);

    function handleLogin(email, password, remember, setErrors, callback = () => null) {
        return new Promise((resolve) => {
            api
                .post('/login', {
                    email,
                    password,
                    remember,
                })
                .then(response => {
                    const storage = remember ? localStorage : sessionStorage;
                    let {token, user} = response.data;
                    const {tree, list} = getWorkspace(user);

                    storage.setItem('token', token);
                    callback();

                    setWorkspaces(list);
                    setTreeWorkspaces(tree);

                    setUser(user);
                    setAuthenticated(true);
                })
                .catch(error => {
                    if (error.response?.status === 422) setErrors(error.response?.data?.errors || {errors: {}});
                    else newAlert('Erro ao tentar fazer login. Tente novamente mais tarde.');
                })
                .finally(resolve);
        });
    }

    function handleLogout(revoke = false, callback = () => null) {
        function logoutLocal() {
            localStorage.removeItem('token');
            sessionStorage.removeItem('token');
            localStorage.removeItem('workspace');
            callback();
            setAuthenticated(false);
            setUser(null);
        }

        if (revoke) api.post('/logout').finally(logoutLocal);
        else {
            newAlert('Sessão expirada. Faça login novamente.', 'warning');
            logoutLocal();
        }
    }

    function refreshUser() {
        api.get('/user')
            .then(response => {
                let instance = response?.data?.data;
                const {tree, list} = getWorkspace(user);

                setWorkspaces(list);
                setTreeWorkspaces(tree);

                setUser(instance)
            })
            .catch(error => {
                if (error.response?.status === 401) handleLogout();
                else newAlert('Erro ao tentar atualizar o usuário. Tente novamente mais tarde.');
            });
    }

    function can(permission, root) {
        if (user.is_admin) return true;

        if (workspace === 0 && !root) return false;

        return user.permissions[root]?.includes(permission) ?? false;
    }

    function emptyPermissions() {
        if (user.is_admin) return false;

        return Object.entries(user.permissions).every(([i, permissions]) => permissions.length === 0);
    }

    return {
        authenticated,
        loading,
        user,
        setUser,
        can,
        emptyPermissions,
        handleLogin,
        handleLogout,
        refreshUser,
        treeWorkspaces,
        workspaces,
        workspace,
        setWorkspace: setWorkspacePersistent,
        selectWorkspace,
        setSelectWorkspace,
        loadingBackdrop,
        setLoadingBackdrop,
    };
}

export default useAuthProvider;

export {useAuthProvider}