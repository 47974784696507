import {Button, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import RefreshIcon from '@mui/icons-material/Refresh';

function RefreshedAt({hour, className, refresh = () => null, size = 'grow', variant = 'outlined'}) {
    return hour ? (
        <Grid
            container
            size={size}
            className={className}
            alignItems="end"
            direction="column"
        >
            <Typography className="font-light text-xs text-end" sx={{color: variant === 'contained' ? 'primary.main' : 'text.primary'}}>
                Atualizado {hour}<br/>
                <Button
                    variant={variant}
                    onClick={refresh}
                    endIcon={<RefreshIcon/>}
                >
                    Atualizar
                </Button>
            </Typography>
        </Grid>
    ) : null;
}

export default RefreshedAt;

export {RefreshedAt};