import {
    Alert,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TableRow,
    Tooltip,
    useMediaQuery,
    useTheme
} from '@mui/material';
import {BtnClose, ConfirmDisassociateUser, NewFolderUser, StyledTableCell, StyledTableRow, TablePagination} from './';
import {useContext, useEffect, useState} from 'react';
import {AlertContext, api} from '../services';
import {useAuth} from '../hooks';
import AddIcon from '@mui/icons-material/Add';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import FolderCopyIcon from '@mui/icons-material/FolderCopyOutlined';

function ListFolderRole({showFolders, setShowFolders}) {
    const theme = useTheme();
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [associate, setAssociate] = useState(false);
    const [confirmDisassociate, setConfirmDisassociate] = useState(false);

    useEffect(() => {
        if (!showFolders) return;

        setLoading(true);

        api
            .get(`/users/${showFolders.id}/folders`, {
                params: {
                    page: page + 1,
                    per_page: perPage,
                },
            })
            .then(response => setData(response.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else newAlert(error.response?.data?.message ?? 'Erro ao tentar carregar as pastas o usuário. Tente novamente mais tarde.');
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line
    }, [showFolders, page, perPage]);

    return (
        <>
            <Dialog
                fullWidth
                maxWidth="md"
                open={Boolean(showFolders?.id)}
                onClose={() => setShowFolders(false)}
                fullScreen={fullScreen}
            >
                <DialogTitle>
                    <Box>
                        Pastas do usuário <span className="font-bold">{showFolders?.name}</span>
                    </Box>
                    <BtnClose onClick={() => setShowFolders(false)}/>
                </DialogTitle>
                <DialogContent dividers>
                    <Box className="text-center mb-3">
                        <Button className="w-9/12" onClick={() => setAssociate({...showFolders})}>
                            <AddIcon/>
                            &nbsp;Adicionar Pasta
                        </Button>
                    </Box>
                    <TableContainer component={Paper}>
                        <Table
                            stickyHeader
                            aria-label="sticky table"
                            sx={{minWidth: 250}}
                            size="small"
                        >
                            <TableHead>
                                <TableRow>
                                    {
                                        ['Empresa', 'Pasta', 'Função', 'Ação'].map(text => (
                                            <StyledTableCell align="center" key={text}>
                                                {text}
                                            </StyledTableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    loading ? (
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">
                                                    <CircularProgress className="m-auto my-3"/>
                                                </TableCell>
                                            </TableRow>
                                        ) :
                                        (
                                            (data && data?.data?.map(({company, folder, role}, i) => (
                                                <StyledTableRow key={folder?.id}>
                                                    <StyledTableCell align="center" valign="middle">
                                                        <Box className="flex items-center justify-center">
                                                            <CorporateFareIcon/>
                                                            <span className="ml-1 mt-1">
                                                                {company?.name}
                                                            </span>
                                                        </Box>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <Box className="flex items-center justify-center">
                                                            {
                                                                company?.id === folder?.id ? (
                                                                    <>
                                                                        <FolderCopyIcon/>
                                                                        <span className="ml-1 font-bold" style={{marginTop: 3}}>
                                                                            TODAS
                                                                        </span>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <FolderIcon/>
                                                                        <span className="ml-1" style={{marginTop: 3}}>
                                                                            {folder?.name}
                                                                        </span>
                                                                    </>
                                                                )
                                                            }
                                                        </Box>
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        {role?.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="center">
                                                        <Tooltip title="Desassociar">
                                                            <IconButton
                                                                aria-label="Desassociar"
                                                                color="error"
                                                                onClick={() => {
                                                                    setConfirmDisassociate({
                                                                        index: i,
                                                                        folder: {
                                                                            id: folder?.id,
                                                                            name: folder?.name,
                                                                        },
                                                                        user: {
                                                                            id: showFolders?.id,
                                                                            name: showFolders?.name,
                                                                        },
                                                                    });
                                                                }}
                                                            >
                                                                <DeleteIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )))
                                        )
                                }
                                {
                                    !loading && !data?.data?.length && (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                <Alert severity="warning">
                                                    Nenhuma pasta encontrada para o usuário.
                                                </Alert>
                                            </TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={4}
                                        rowsPerPage={perPage}
                                        setPerPage={setPerPage}
                                        page={page}
                                        setPage={setPage}
                                        count={data?.meta?.total ?? 0}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button
                        autoFocus
                        onClick={() => setShowFolders(false)}
                        variant="outlined"
                    >
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
            <ConfirmDisassociateUser
                confirmDisassociate={confirmDisassociate}
                setConfirmDisassociate={setConfirmDisassociate}
                setData={setData}
            />
            <NewFolderUser
                associate={associate}
                setAssociate={setAssociate}
                setData={setShowFolders}
                type="user"
            />
        </>
    );
}

export default ListFolderRole;

export {ListFolderRole}