import {useParams} from 'react-router-dom';
import {useContext, useEffect, useState} from 'react';
import {AlertContext, api, TreeContext} from '../services';
import {useAuth, useTitle} from '../hooks';
import {Box, Breadcrumbs, Button, Chip, CircularProgress, Paper, Tab, Tooltip, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {ActiveAlerts, ConfirmDisassociateMonitor, LatestStatus, RefreshedAt} from '../components';
import CircleIcon from '@mui/icons-material/Circle';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import NumbersOutlinedIcon from '@mui/icons-material/NumbersOutlined';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import Battery6BarIcon from "@mui/icons-material/Battery6Bar";

function DetailsLevelDisplay() {
    const {id} = useParams();
    const {setTitle} = useTitle();
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [tab, setTab] = useState('1');
    const [loading, setLoading] = useState(true);
    const [levelDisplay, setLevelDisplay] = useState({});
    const {setShowConfirmDisassociate} = useContext(TreeContext);
    const statuses = {
        null: {
            color: 'text.secondary',
            text: 'Inativo',
        },
        true: {
            color: 'success.main',
            text: 'Ativo',
        },
        false: {
            color: 'error.main',
            text: 'Saturado',
        },
    };

    function loadLevelDisplay() {
        setLoading(true);

        api.get('/level-displays/' + id)
            .then(response => setLevelDisplay(response?.data?.data))
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 404) newAlert('O visor de nível solicitado não foi encontrado.');
                else if (code === 403) newAlert('Você não tem permissão para acessar os detalhes desse visor de nível.');
                else newAlert('Erro ao tentar carregar os detalhes desse visor de nível. Tente novamente mais tarde.');

                setLevelDisplay({});
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => setTitle('Ver visor de nível', 'Detalhes do visor de nível #' + id), [id, setTitle]);

    // eslint-disable-next-line
    useEffect(loadLevelDisplay, [id]);

    if (loading) return <CircularProgress className="m-auto"/>;

    if (!levelDisplay) return;

    return (
        <>
            <Box className="self-start w-full">
                <Box>
                    <Typography
                        variant="caption"
                        component="span"
                        className="font-bold"
                    >
                        {
                            levelDisplay.location ? 'Esse visor de nível está localizado em:' : 'Visor de nível não instalado'
                        }
                    </Typography>
                    <Box
                        role="presentation"
                        className="my-2"
                    >
                        <Breadcrumbs
                            aria-label="breadcrumb"
                            separator={<NavigateNextIcon sx={{marginBottom: '12px'}}/>}
                        >
                            {
                                levelDisplay.location?.folders?.map((folder, i) => (
                                    <Chip
                                        icon={i === 0 ?
                                            <CorporateFareIcon sx={{mr: 0.5}} fontSize="small"/> :
                                            <FolderIcon sx={{mr: 0.5}} fontSize="small"/>
                                        }
                                        label={folder}
                                        className="mb-3"
                                        key={i}
                                    />
                                ))
                            }
                            {
                                levelDisplay?.location?.machine && (
                                    <Chip
                                        icon={<PrecisionManufacturingIcon sx={{mr: 0.5}} fontSize="small"/>}
                                        label={levelDisplay.location.machine}
                                        className="mb-3"
                                    />
                                )
                            }
                            {
                                levelDisplay?.location?.subset && (
                                    <Chip
                                        icon={<SettingsIcon sx={{mr: 0.5}} fontSize="small"/>}
                                        label={levelDisplay.location.subset}
                                        className="mb-3"
                                    />
                                )
                            }
                            {
                                levelDisplay?.location?.component && (
                                    <Chip
                                        icon={<RadioButtonCheckedIcon sx={{mr: 0.5}} fontSize="small"/>}
                                        label={levelDisplay.location.component}
                                        className="mb-3"
                                    />
                                )
                            }
                        </Breadcrumbs>
                    </Box>
                </Box>
                <RefreshedAt
                    className="my-1"
                    hour={levelDisplay.hour}
                    refresh={loadLevelDisplay}
                />
                <Grid container spacing={2}>
                    <Grid size={{xs: 12, sm: 3, md: 4}}>
                        <Paper className="p-2">
                            <Tooltip
                                title="ID"
                                className="flex items-center justify-center"
                            >
                                <Box>
                                    <NumbersOutlinedIcon className="mr-2"/>
                                    {levelDisplay.id}
                                </Box>
                            </Tooltip>
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 12, sm: 9, md: 8}}>
                        <Paper className="p-2 break-all">
                            <Tooltip
                                title="Número de Série"
                                className="flex items-center justify-center"
                            >
                                <Box>
                                    <AbcOutlinedIcon className="mr-2"/>
                                    {levelDisplay.serial}
                                </Box>
                            </Tooltip>
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <Paper className="p-2 break-all">
                            <Tooltip
                                title="Status"
                                className="flex items-center justify-center"
                            >
                                <Box>
                                    <CircleIcon className="mr-2" sx={{color: statuses[levelDisplay.status].color}}/>
                                    {statuses[levelDisplay.status].text}
                                </Box>
                            </Tooltip>
                        </Paper>
                    </Grid>
                    <Grid size={{xs: 12, sm: 6}}>
                        <Paper className="p-2">
                            <Tooltip
                                title="Bateria"
                                className="flex items-center justify-center"
                            >
                                <Box>
                                    <Battery6BarIcon className="mr-2"/>
                                    {levelDisplay.battery ? levelDisplay.battery.toLocaleString() + '%' : 'Sem informação'}
                                </Box>
                            </Tooltip>
                        </Paper>
                    </Grid>
                </Grid>
                <TabContext value={tab}>
                    <TabList
                        value={tab}
                        onChange={(event, newValue) => setTab(newValue)}
                        className="my-3"
                        aria-label="Detalhamento do Lubrificador"
                        centered
                    >
                        <Tab icon={<NotificationImportantOutlinedIcon/>} label="Alertas ativos" value="1"/>
                        <Tab icon={<HistoryOutlinedIcon/>} label="Últimos status" value="2"/>
                    </TabList>
                    <TabPanel value="1">
                        <ActiveAlerts activeAlerts={levelDisplay?.active_alerts} type="levelDisplay"/>
                    </TabPanel>
                    <TabPanel
                        value="2"
                        className="text-center"
                    >
                        <LatestStatus levelDisplay={id} dictionary={statuses}/>
                    </TabPanel>
                </TabContext>
            </Box>
            {
                levelDisplay.location && levelDisplay.disassociate && (
                    <>
                        <Box className="self-end w-full">
                            <Button
                                fullWidth
                                variant="outlined"
                                startIcon={<LinkOffIcon/>}
                                color="error"
                                type="submit"
                                onClick={() => setShowConfirmDisassociate({
                                    type: 'levelDisplay',
                                    id: levelDisplay.id,
                                })}
                            >
                                Desassociar visor de nível
                            </Button>
                        </Box>
                        <ConfirmDisassociateMonitor callback={loadLevelDisplay}/>
                    </>
                )
            }
        </>
    );
}

export default DetailsLevelDisplay;

export {DetailsLevelDisplay};