import {useContext} from 'react';
import {TreeContext} from '../services';
import useAuth from '../hooks/useAuth';
import {ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import EditIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import InfoIcon from '@mui/icons-material/InfoOutlined';

function MenuEditAsset() {
    const {user, can} = useAuth();
    const {menu, setMenu, setManageAsset, setManageFolder, setShowConfirmDelete, setListUsers} = useContext(TreeContext);
    const {type, root, id} = menu?.data ?? {};
    const isRoot = type === 'folder' && root === id;

    return (
        <Menu
            id="menu-edit-asset"
            anchorEl={menu?.anchorEl}
            open={menu?.type === 'edit'}
            onClose={() => setMenu(false)}
        >
            {
                type !== 'folder' && can('update_subfolders_assets', root) && (
                    <MenuItem
                        onClick={() => {
                            setMenu(false);
                            setManageAsset(menu?.data);
                        }}
                    >
                        <ListItemIcon>
                            <EditIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Renomear
                        </ListItemText>
                    </MenuItem>
                )
            }
            {
                type === 'folder' && (
                    <MenuItem
                        onClick={() => {
                            setManageFolder({id: menu.data.id, action: 'show', root: root})
                            setMenu(false);
                        }}
                    >
                        <ListItemIcon>
                            <InfoIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Detalhar
                        </ListItemText>
                    </MenuItem>
                )
            }
            {
                type === 'folder' && can(isRoot ? 'update_root_folder' : 'update_subfolders_assets', root) && (
                    <MenuItem
                        onClick={() => {
                            setManageFolder({id: menu.data.id, action: 'edit'})
                            setMenu(false);
                        }}
                    >
                        <ListItemIcon>
                            <EditIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Editar
                        </ListItemText>
                    </MenuItem>
                )
            }
            {
                type === 'folder' && can('manage_user_folder', root) && (
                    <MenuItem
                        onClick={() => {
                            setMenu(false);
                            setListUsers(menu?.data);
                        }}
                    >
                        <ListItemIcon>
                            <GroupIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Usuários
                        </ListItemText>
                    </MenuItem>
                )
            }
            {
                ((!isRoot && can('delete_subfolders_assets', root)) || user?.is_admin) && (
                    <MenuItem
                        onClick={() => {
                            setMenu(false);
                            setShowConfirmDelete(menu?.data);
                        }}
                    >
                        <ListItemIcon>
                            <DeleteIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>
                            Deletar
                        </ListItemText>
                    </MenuItem>
                )
            }
        </Menu>
    );
}

export default MenuEditAsset;

export {MenuEditAsset};