import {styled} from '@mui/material/styles';
import {
    TreeItem2Content,
    TreeItem2GroupTransition,
    TreeItem2IconContainer,
    TreeItem2Root
} from '@mui/x-tree-view/TreeItem2';

const CustomTreeItemRoot = styled(TreeItem2Root, {shouldForwardProp: prop => prop !== 'isConfiguring'})(({theme, isConfiguring}) => ({
    color: theme.palette.text.primary,
    width: isConfiguring ? 335 : 295,
    [theme.breakpoints.up('md')]: {
        width: isConfiguring ? 385 : 345,
    },
}));

const CustomTreeItemContent = styled(TreeItem2Content)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),

    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused': {
        backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
        color: 'var(--tree-view-color)',
    },
}));

const CustomTreeItemIconContainer = styled(TreeItem2IconContainer)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    height: '48px',
    marginRight: theme.spacing(1),
}));

const CustomTreeItemGroupTransition = styled(TreeItem2GroupTransition)(({theme}) => ({
    marginLeft: 15,
    paddingLeft: 15,
    borderLeft: `2px solid ${theme.palette.text.primary}`,
}));

export {CustomTreeItemRoot, CustomTreeItemContent, CustomTreeItemIconContainer, CustomTreeItemGroupTransition};