function getItemIds(folders) {
    if (!folders)  return [];

    const ids = [];

    function getItemId(item) {
        if (item?.children?.length !== 0) {
            ids.push(`${item.type}-${item.id}`);
        }

        if (item.children) {
            item.children.forEach(child => {
                getItemId(child);
            });
        }
    }

    folders.map(folder => getItemId(folder));

    return ids;
}

export default getItemIds;

export {getItemIds};