import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import useAuth from '../hooks/useAuth';
import {filterObject} from '../utils';
import {Alert, BtnClose, FindCompany, FindFolder, FindUser} from './';
import {AlertContext, api} from '../services';
import {useContext, useEffect, useState} from 'react';
import AddIcon from '@mui/icons-material/Add';

function NewFolderUser({associate, setAssociate, setData, type}) {
    const {handleLogout} = useAuth();
    const {newAlert} = useContext(AlertContext);
    const [role, setRole] = useState('');
    const [user, setUser] = useState(null);
    const [errors, setErrors] = useState({});
    const [company, setCompany] = useState(null);
    const [folder, setFolder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [roles, setRoles] = useState([{id: 0, name: 'Carregando ...'}]);

    function handleChange(event) {
        if (event.target.name === 'role') {
            setRole(event.target.value);
        }

        if (errors[event.target.id]) {
            setErrors(filterObject(errors, index => index !== event.target.id));
        }
    }

    function handleSubmit(event) {
        event.preventDefault();

        setLoading(true);

        api
            .post(`/folders/${folder?.id}/users/${user?.id}`, {
                role,
            })
            .then(response => {
                newAlert(response?.data?.message, 'success');

                setAssociate(false);
                setRole('');
                setData(data => {
                    let newData = {...data};

                    newData[`${user?.id}_${folder?.id}`] = true;

                    return newData;
                });
            })
            .catch(error => {
                const code = error.response?.status;

                if (code === 401) handleLogout();
                else if (code === 422) setErrors(error.response?.data?.errors || {errors: {}});
                else {
                    newAlert(error.response?.data?.message ?? `Erro ao tentar associar. Tente novamente mais tarde.`);
                }
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        if (associate?.id && roles[0].id === 0) {
            api.get('/roles/all')
                .then(response => setRoles(response.data?.data))
                .catch(error => {
                    const code = error.response?.status;

                    if (code === 401) handleLogout();
                    else newAlert(error.response?.data?.message ?? 'Erro ao carregar as funções. Tente novamente mais tarde.');
                })
        }
        // eslint-disable-next-line
    }, [associate]);

    useEffect(() => {
        if (!associate?.id) {
            setRole('');
            setCompany(null);
            setFolder(null);
            return;
        }

        if (type === 'folder') {
            setFolder({id: associate?.id, name: associate?.name});
            setUser(null);

        } else {
            setUser({id: associate?.id, name: associate?.name});
            setFolder(null);
        }
    }, [type, associate]);

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={Boolean(associate?.id)}
            onClose={() => setAssociate(false)}
            PaperProps={{
                component: 'form',
                onSubmit: handleSubmit,
            }}
        >
            <DialogTitle>
                <Box>
                    Associar {type === 'user' ? 'pasta ao usuário' : (
                        <>
                            usuário a pasta <span className="font-bold">{associate?.name}</span>
                        </>
                    )}
                </Box>
                <BtnClose onClick={() => setAssociate(false)} />
            </DialogTitle>
            <DialogContent dividers>
                <Grid
                    container
                    className="mb-2 mt-1"
                    spacing={2}
                >
                    {
                        type === 'user' ? (
                            <>
                                <Grid size={12}>
                                    <FindCompany
                                        company={company}
                                        setCompany={setCompany}
                                        setFolder={setFolder}
                                    />
                                </Grid>
                                <Grid size={12}>
                                    <FindFolder
                                        folders={folder}
                                        company={company}
                                        setFolder={setFolder}
                                    />
                                </Grid>
                            </>
                        ) : (
                            <Grid size={12}>
                                <FindUser
                                    user={user}
                                    setUser={setUser}
                                />
                            </Grid>
                        )
                    }
                    <Grid size={12}>
                        <Alert
                            severity="error"
                            className="flex justify-center mb-2"
                            mmessage={errors?.role}
                        />
                        <FormControl fullWidth>
                            <InputLabel id={`select-associate-folder-label`}>Função</InputLabel>
                            <Select
                                labelId={`select-associate-folder-label`}
                                id={`select-associate-folder`}
                                name="role"
                                value={role}
                                label="Função"
                                onChange={handleChange}
                            >
                                {
                                    roles.map(role => (
                                        <MenuItem
                                            key={role.id}
                                            value={role.id}
                                        >
                                            {role.name}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    startIcon={<AddIcon/>}
                    disabled={Boolean(errors?.role) || !folder || !user || !role}
                    fullWidth
                    type="submit"
                    loading={loading}
                >
                    Associar
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

export default NewFolderUser;

export {NewFolderUser};